import { Component, Input, OnInit } from '@angular/core';
import { HistogramsService } from '../common/bha.service'
import { debug } from 'util';

@Component({
    selector: 'histogram',
    providers: [HistogramsService],
    template: `
    <ng-template [ngIf]="data.length>0" [ngIfElse]="noData">
        <kendo-chart>
            <kendo-chart-title text="{{title}}"></kendo-chart-title>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item 
                    [title]="{ text: xAxisTitle }"
                    [categories]="categories"
                    [maxDivisions]="10"
                    [labels]="{ rotation: 'auto' }"
                    [justified]="{ value: true }">
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: 'Hours' }"></kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-series>
                <kendo-chart-series-item type="column" [stack]="true" [gap]=".25" [spacing]=".25" [data]="greenData" color="#85B02E"></kendo-chart-series-item>
                <kendo-chart-series-item type="column" [stack]="true" [gap]=".25" [spacing]=".25" [data]="yellowData" color="#FFD814"></kendo-chart-series-item>
                <kendo-chart-series-item type="column" [stack]="true" [gap]=".25" [spacing]=".25" [data]="redData" color="#CC0000"></kendo-chart-series-item>
            </kendo-chart-series>            
        </kendo-chart>
    </ng-template>
    <ng-template #noData>
        <p class="lead">No histogram data was found for this type ({{type}}).</p>
    </ng-template>   
    `
})
export class HistogramComponent implements OnInit {

    //The run ID for which the redundant runs list is displayed
    @Input() public runId: number;

    //The type of histogram to be rendered (see HDBS.DrillBitSensor.HistogramType.[TypeName])
    @Input() public type: string;

    public title:string;
    public xAxisTitle:string;
    public data = [];
    public greenData = [];
    public yellowData = [];
    public redData = [];
    public categories = [];

    constructor(private service: HistogramsService) {
    }

    public ngOnInit(): void {
        this.service.getHistogramByRunId(this.runId, this.type).subscribe(r => {
            for (let i=0; i<r.data.length; i++)
            {
                let append = "";
                if (this.type === "Temperature_deg_C")
                    append = "&deg;";
                else if (this.type === "WhirlRadius_mm")
                    append = "mm";
                this.categories.push(`${r.data[i].BucketMin}${append}`);

                if (r.data[i].Count < 0)
                this.data.push(0);
              else
                this.data.push(r.data[i].Count/60/60);
            }

            this.greenData = Object.assign([], this.data);
            this.yellowData = Object.assign([], this.data);
            this.redData = Object.assign([], this.data);
            for (let index = 0; index < this.data.length; index++) {
                if (index <= 9)
                {
                    this.yellowData[index] = 0;
                    this.redData[index] = 0;
                }
                else if (index > 9 && index < 15)
                {
                    this.greenData[index] = 0;
                    this.redData[index] = 0;
                }
                else
                {
                    this.greenData[index] = 0;
                    this.yellowData[index] = 0;
                }
            }

            switch(this.type) { 
                case "AxialVibration_g": { 
                    this.title = "Axial Vibration";
                    this.xAxisTitle = "Axial Vibration (g)";
                    break; 
                } 
                case "LateralVibration_g": { 
                    this.title = "Lateral Vibration";
                    this.xAxisTitle = "Lateral Vibration (g)";
                    break; 
                 } 
                 case "StickSlipIndicator": { 
                    this.title = "Stick Slip Indicator";
                    this.xAxisTitle = "";
                    break; 
                 } 
                 case "Temperature_deg_C": { 
                    this.title = "Board Temperature";
                    this.xAxisTitle = "Temperature &deg;C";
                    break; 
                 } 
                 case "TorsionalVibration_deg_per_sec_2": { 
                    this.title = "Torsional Vibration";
                    this.xAxisTitle = "Torsional Vibration (&deg;/s&#178;)";
                    break; 
                 } 
                 case "WhirlRadius_mm": { 
                    this.title = "Whirl Radius";
                    this.xAxisTitle = "Radius (mm)";
                    break; 
                 }                                                                 
                default: { 
                    this.title = "Unknown data";
                    this.xAxisTitle = "Unknown scale";
                    break; 
                } 
            }             

          });  
     }
}