import { Input, Component, ViewChild } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'alert-closeable',
  template: `
    <ngb-alert class="col" [type]="type" *ngIf="visible" (close)="visible = !visible"><span class="lead">{{ message }}</span></ngb-alert>
  `,
  styles: ['ngb-alert { position:absolute; z-index:99999; width: 80%; }']
})
export class AlertCloseableComponent {
    private _message = '';

    @Input()
    public visible:boolean = false;

    @Input()
    public type:string;

    @Input()
    set message(message: string) {
      this._message = message;
      this.visible = true;
    }
   
    get message(): string { return this._message; }

    constructor() { 
    }
}
