import { Component, OnInit, ViewChild } from '@angular/core';
// import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { BoardsService, RunsService, AggregateHistogramsService } from '../../common/bha.service'
import { MapComponent } from '../../common/map/map.component'

@Component({
  selector: 'board-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  @ViewChild(MapComponent) map:MapComponent;

  private boardSn:string = '';
  public board$:Observable<any>;
  public sparkLineFileWriteErrors = [];
  public sparkLineSensorErrors = [];
  public sparkLineParseErrors = [];
  public histogramData = [];
  public mapLoaded:boolean = true;

  public state: State = {
    skip: 0,
    take: 5,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public pageSize = 10;
  public skip = 0;

  constructor(private route: ActivatedRoute,
    private histogramService: AggregateHistogramsService,
    private boardsService: BoardsService,
    private router: Router,
    private runsService: RunsService) { } //,private location: Location

  ngOnInit() {
    const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
    if (userProfile == null) { 
      this.route.paramMap.subscribe(r => {
        this.boardSn = r.get("id");});       
        setTimeout(() => {
          window.location.reload();      
        },1000);
        this.router.navigate(['/board/details/', this.boardSn]);
    }
    this.route.paramMap.subscribe(r=>{
      this.boardSn = r.get("id");
      this.boardsService.getBoardBySerialNumber(this.boardSn).subscribe(r => {
        this.board$ = r.data;
      });
      this.histogramService.getTemperatureHistogramByBoardSerialNumber(this.boardSn).subscribe(r => {
        r.data.forEach(x=> {
          if (x.Count < 0)
            this.histogramData.push(0);
          else
            this.histogramData.push(x.Count/60/60);
        });
      });  
      // this.runsService.getTotalFileWriteErrorsByBoardSerialNumber(this.boardSn).subscribe(r => {
      //   r.data.forEach(x=> this.sparkLineFileWriteErrors.push(x.TotalFileWriteErrors));
      // });      
      // this.runsService.getTotalSensorErrorsByBoardSerialNumber(this.boardSn).subscribe(r => {
      //   r.data.forEach(x=> this.sparkLineSensorErrors.push(x.TotalSensorErrors));
      // });     
      // this.runsService.getTotalParseErrorsByBoardSerialNumber(this.boardSn).subscribe(r => {
      //   r.data.forEach(x=> this.sparkLineParseErrors.push(x.TotalParseErrors));
      // });                 
    });
    // if(sessionStorage.getItem('accessToken') == null)
    //   setTimeout(() => {location.reload()},200);
  } 

  mapLoadedEvent(status: boolean) {
    this.mapLoaded = status;
    if (status)
    {
      this.runsService.getRunsByBoardSerialNumber(this.boardSn).subscribe(r => {
        
        //pass in unique coordinates, well name and run number
        r.data.forEach(x => {
          this.map.addMarker(+x.Longitude, +x.Latitude, x.WellName, x.RunNumber);
        });

        this.map.drawMarkers();
      });           
    }
  }
}