<header>
    <nav class="navbar navbar-expand-md">
        <a class="navbar-brand" routerLink="run/list">
            <div><span class="logo">BHA Automation</span></div>
        </a>    
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainMenu">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mainMenu">
            <ul class="navbar-nav mr-auto p-2">
                <li ngbDropdown class="nav-item dropdown">
                    <span ngbDropdownToggle style="cursor: pointer;" class="nav-link dropdown-toggle" id="mainMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Main Menu</span>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="mainMenu2">
                        <ng-container *ngFor="let menuItem of headerData.MenuItems">
                            <div *ngIf="menuItem.Separator; else elseMenuItem" class="dropdown-divider"></div>
                            <a class="dropdown-item" *ngIf="menuItem.Route != null;" routerLink="{{menuItem.Route}}">{{menuItem.DisplayName}}</a>
                            <a class="dropdown-item" *ngIf="menuItem.Url != null;" href="{{menuItem.Url}}">{{menuItem.DisplayName}}</a>                           
                        </ng-container>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="{{datcitoolboxUrl}}/Home/WhatsNew">What's New</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="{{datcitoolboxUrl}}/Home/Resources">Resources</a>
                </li>
            </ul>
            <!-- <form action="/ADESC/Search" class="form-inline mt-2 mt-md-0" id="quickSearchForm" method="get" role="search">
                <div id="quickSearch" class="form-group">
                    <input type="text" class="form-control" id="Quick" name="Quick" placeholder="Mat Num, Bit Type, etc">&nbsp;
                </div>
                <input type="hidden" name="s" value="1">
                <button type="submit" class="btn btn-default" id="quickSearchSubmit">Search</button>
            </form>
            <div id="notification-container-large">
                <div id="notifications-toggle" class="notifications-icon-container">
                    <span class="notifications-icon oi oi-bell" aria-hidden="true"></span>
                    <span class="badge badge-notifications" id="unreadNotificationCount"></span>
                </div>
            </div>            -->
            <ul class="navbar-nav">
                <li ngbDropdown class="nav-item dropdown">
                    <!-- <span ngbDropdownToggle style="cursor: pointer;" class="nav-link dropdown-toggle" id="mainMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{profile?.givenName + ' ' + profile?.surname}}</span> -->
                    <span ngbDropdownToggle style="cursor: pointer;" class="nav-link dropdown-toggle" id="mainMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{profile?.givenName + ' ' + profile?.surname}}</span>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="mainMenu3">
                        <a class="dropdown-item" [routerLink]="['profile']">Profile</a>
                        <a class="dropdown-item" (click)="logout()">Logout</a>                        
                    </div>
                </li>
            </ul>               
        </div>
    </nav>
</header>
