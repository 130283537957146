import { Directive, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription, Observable } from 'rxjs';
import { RunsService } from '../common/bha.service';
import { ActivatedRoute } from '@angular/router';

@Directive({
    selector: '[runsBinding]'
})
export class RunsBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
    private serviceSubscription: Subscription;
    queryStringFilter: any = null;
    constructor(private runs: RunsService, grid: GridComponent, private activatedRoute: ActivatedRoute, private location: Location) {
        super(grid);
    }

    public ngOnInit(): void {
        this.grid.loading = true;
        this.activatedRoute.queryParams.subscribe(params => {
            const materialNumber = params['materialnumber'];
            if (materialNumber) {
                this.queryStringFilter = { "field": "BitMaterialNumber", "operator": "eq", "value": +materialNumber };
            }

            const serialNumber = params['serialnumber'];
            if (serialNumber) {
                this.queryStringFilter = { "field": "BitSubSerialNumber", "operator": "eq", "value": serialNumber };
            }
            this.location.replaceState('');
        });
        this.serviceSubscription = this.runs.subscribe((result) => {
            this.grid.loading = false;
            this.grid.data = result;
            this.notifyDataChange();
        });
        //this.runs.queryItWithoutRedundantRuns(this.state);
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }

    public rebind(): void {
        this.grid.loading = true;
        if (this.queryStringFilter) {
            let found = this.state.filter &&
                this.state.filter.filters.some(fil => {
                    return (fil['field'] === this.queryStringFilter.field);
                });
            if (found) {
                this.state.filter.filters.forEach(filter => {
                    if (filter['field'] === this.queryStringFilter.field) {
                        filter['operator'] = "eq";
                        filter["value"] = this.queryStringFilter.value
                    }
                });
            }
            else {
                if (!this.state.filter) {
                    this.state.filter = { "logic": "and", "filters": [] };
                }
                this.state.filter.filters.push(this.queryStringFilter);
            }
            this.queryStringFilter = null;
        }
        this.runs.queryItWithoutRedundantRuns(this.state);
    }
}