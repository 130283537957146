import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { RunsBindingDirective } from '../remote-binding.directive';
import { FileDownloadStatus, MaterialNumberService, RunsService } from 'src/app/common/bha.service';
import { RunService } from 'src/app/common/run.service';
import { GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { ColumnBase } from '@progress/kendo-angular-excel-export';
import { State } from '@progress/kendo-data-query';
import { Statement } from '@angular/compiler';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { SatoriFile, SatoriService } from 'src/app/common/satori.service';

//import { SatoriService } from 'src/app/common/satori.service';
declare var BHA_API: string;
declare var CURRENT_USER_ID: number;
declare var CerebroAppUrl: string;
@Component({
  selector: 'run-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ListComponent implements OnInit {
  BhaApi: string = BHA_API;
  currentUserId: number = CURRENT_USER_ID;
  CerebroAppUrl: string = CerebroAppUrl;
  @ViewChild(RunsBindingDirective, { static: true }) public runsBindingDirective: RunsBindingDirective;
  @ViewChild(GridComponent, { static: true }) public grid: GridComponent;
  @ViewChildren(GridComponent) public grids: QueryList<GridComponent>;
  @ViewChild(ExcelComponent) public excel: ExcelComponent;
  public filedownloadstatus: FileDownloadStatus[];
  public runId: number;
  private exportOption: string;
  public satoriFiles$: SatoriFile[];
  public SummaryFile: SatoriFile;
  constructor(public materialNumberService: MaterialNumberService,
    public runsService: RunsService, private http: HttpClient,
    public runService: RunService,
    private satoriService: SatoriService,
    private titleService: Title) {
    // this.activatedRoute.queryParams.subscribe(params => {
    //     debugger;
    //     const materialNumber = params['materialnumber'];
    //     if (materialNumber)
    //     {
    //         //only force this if state is null (initial page load)
    //         if (!this.grid.filter)
    //             this.grid.filter = { "logic": "and", "filters": [ { "field": "BitMaterialNumber", "operator": "eq", "value": +materialNumber } ] };
    //     }

    //     const serialNumber = params['serialnumber'];
    //     if (serialNumber)
    //     {
    //         //only force this if state is null (initial page load)
    //         if (!this.grid.filter)
    //             this.grid.filter = { "logic": "and", "filters": [ { "field": "BitSubSerialNumber", "operator": "eq", "value": serialNumber } ] };
    //     }      
    // });   
    // satoriService.createAuthorizationHeader();

  }
  public onExportClick(option: string) {
    if (option === 'all') {
      this.excel.fileName = `All Runs.xlsx`;
      this.excel.fetchData = this.allData;
    }
    else {
      this.excel.fileName = `Runs.xlsx`;
      delete this.excel.fetchData;
    }
    this.grid.saveAsExcel();
  }

  public allData = (): Observable<any> => {
    return this.runsService.getAllForExportToExcel({ filter: this.grid.filter, group: this.grid.group, sort: this.grid.sort });
  }

  ngOnInit() {
    // this.runService.getAllRuns().subscribe(r => {
    //   //debugger;
    //   for (let i = 0; i<r.length; i++)
    //   {
    //     console.log('[' + r[i].wellName + '] ' + r[i].wellName);
    //   }
    // });
    const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
    if (userProfile == null) {
      setTimeout(() => {
        window.location.reload();      
      },800);
    }
    this.filedownloadstatus = [];
    this.titleService.setTitle(`BHA Automation - Runs list`);
  }

  public offsetSearch(materialNumber): void {
    window.open(`http://hdbstech/Wizdom/OffSetSearch?WellSearch=matnum&materialnumber=${materialNumber}`);
  }

  public sensorBrdDetails(brSerialNo): void {
    window.open('/board/details/' + brSerialNo);
  }

  public RunDetail(runDetailId): void {
    window.open('/run/details/' + runDetailId);
  }

  public StartTimeDetails(runDetailId): void {
    window.open('/run/details/' + runDetailId);
  }

  public BoardDetail(brSerialNo): void {
    window.open('/board/details/' + brSerialNo);
  }
  //RunDetail(dataItem.ID)
  // public refreshGrid():void
  // {
  //   this.runsBindingDirective.rebind();
  // }

  DownloadStatCSV(value: number, WelName: any, SerialNo: any) {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')
    })
    this.http.get(this.BhaApi + "/StatsFile('" + value + "')", {
      observe: 'response',
      responseType: 'blob',
      headers: reqHeaders
    }).subscribe(response => {
      var fileName = "";
      var contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      this.filedownloadstatus.find(x => x.runId == value && x.fileName.includes("Stats file")).fileName = fileName;
      this.downLoadFile(response.body, fileName);
    });
    const req = new HttpRequest('GET', this.BhaApi + "/StatsFile('" + value + "')", {
      responseType: 'blob',
      headers: reqHeaders,
      reportProgress: true,
      observe: 'body',
    });
    var fileName = "";
    var contentDisposition = req.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    this.filedownloadstatus.push({
      fileName: fileName == "" ? "Downloading Stats file for " + WelName + "_" + SerialNo : fileName,
      percentage: 0,
      isDownloading: true,
      runId: value,
    });
    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.filedownloadstatus.find(x => x.runId == value && x.fileName.includes("Stats file")).percentage = Math.round(100 * event.loaded / event.total);
      }
    });
  }
  downLoadFile(data: any, type: any): void {
    let blob = new Blob([data], {
      type: type.split(".").pop()
    });
    let url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = type;//+ ".csv";
    link.click();
    window.URL.revokeObjectURL(url);
    //this.filedownloadstatus.find(x => x.fileName == type).isDownloading = false;
    this.filedownloadstatus.forEach((value, index) => {
      if (value.fileName == type) this.filedownloadstatus.splice(index, 1);
    });
  }

  DownloadPdf(value: number, userId: Variable, WelName: any, SerialNo: any) {
    try {
      const reqHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')
      })
      // var satoriFiles = this.http.get<SatoriFile[]>(BHA_API + "/SatoriFile('" + value + "')", { headers: reqHeaders });
      // this.satoriService.getSatoriFilesByRunUUID(value.toString()).subscribe(response => {
      //   this.satoriFiles$ = response;
      // });
      // && (x.fileName.includes("_DBS_Stats_Merged_") ||      (x.fileName.includes("_DBS_Stats_") && x.fileName.includes("Merged")))
      this.http.get(this.BhaApi + "/RunSummaryReport('" + value + "')" + "?u=" + userId, {
        observe: 'response',
        responseType: 'blob',
        headers: reqHeaders
      }).subscribe(response => {
        var fileName = "";
        var contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        this.filedownloadstatus.find(x => x.runId == value && x.fileName.includes("Run Summary Report")).fileName = fileName;
        this.downLoadFile(response.body, fileName);
      });
      const req = new HttpRequest('GET', this.BhaApi + "/StatsFile('" + value + "')", {
        responseType: 'blob',
        headers: reqHeaders,
        reportProgress: true,
        observe: 'body',
      });
      var fileName = "";
      var contentDisposition = req.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      this.filedownloadstatus.push({
        fileName: fileName == "" ? "Downloading Run Summary Report for : " + WelName + "_" + SerialNo : fileName,
        percentage: 0,
        isDownloading: true,
        runId: value,
      });
      this.http.request(req).subscribe(event => {
        //this.SummaryFile =this.satoriFiles$.find(x => x.fileType == "CerebroRaw" && x.FileName.toUpperCase().endsWith(".CSV"))[0];

        if (event.type === HttpEventType.DownloadProgress) {
          this.filedownloadstatus.find(x => x.runId == value && x.fileName.includes("Run Summary Report")).percentage = Math.round(100 * event.loaded / event.total);
        }
      });
    }
    catch (error) {
      debugger;
    }
  }

  downLoadPdfFile(data: any, type: any): void {
    //  debugger;
    let blob = new Blob([data], {
      type: type.split(".").pop()
    });
    let url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = type;
    link.click();
    window.URL.revokeObjectURL(url);
    //this.filedownloadstatus.find(x => x.fileName == type).isDownloading = false;
    this.filedownloadstatus.forEach((value, index) => {
      if (value.fileName == type) this.filedownloadstatus.splice(index, 1);
    });
  }

}