import { Component, Inject, Injectable, OnDestroy, OnInit, } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
//import { ApplicationInsightsService } from './services/logging.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ApplicationInsightsService } from './services/logging.service';

declare var ApiClientId: string;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

@Injectable()
export class AppComponent implements OnInit, OnDestroy {
  title = 'BHA Automation';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  userForm: any;
  Length: number;
  options: any;
  ApiClientId: string = ApiClientId;
  tktTime: Date;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private titleService: Title,
    private ApplicationInsightsService: ApplicationInsightsService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.login();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  public generateToken() {
    var Scope = `api://${ApiClientId}/access_as_user`;
    this.authService.instance.acquireTokenSilent({
      scopes: [Scope],
      account: this.authService.instance.getAllAccounts()[0],
      forceRefresh: true
    }).then(result => {
      sessionStorage.setItem('accessToken', result.accessToken);
      this.tktTime = result.expiresOn;
      this.startAutoTokenRequest(result.expiresOn);
    })
    this.authService.instance
  }

  startAutoTokenRequest(accessToken: Date) {
    const msalToken = sessionStorage.getItem('accessToken');
    if (msalToken !== null) {
      if (accessToken < new Date(Date.now() - (5 * 60000))) {
        this.generateToken();
      }
    }
    setTimeout(this.generateToken.bind(this), 300000);
  }

  login() {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.authService.instance.setActiveAccount(accounts[0]);
    }
    //this.generateToken();
    this.authService.instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        this.authService.instance.setActiveAccount(account);
      }
      else {
        //console.log(event.eventType); 
        //console.log(event.payload.account); 

      }
    },
    );
    this.authService.instance.handleRedirectPromise().then(authResult => {
      // Check if user signed in 
      const account = this.authService.instance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page 
        this.authService.instance.loginRedirect();
      }
    }).catch(err => {
      // TODO: Handle errors
      console.log(err);
    });
    this.generateToken();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

function tokenNotExpired(token: string): boolean {
  throw new Error('Function not implemented.');
}

