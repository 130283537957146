import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { IgxIconModule } from 'igniteui-angular';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    // IgxIconModule
  ],
  declarations: [HeaderComponent,FooterComponent],
  exports: [HeaderComponent,FooterComponent]
})
export class CommonUiModule { }
