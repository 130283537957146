import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RunsService } from '../common/bha.service'
import { AlertCloseableComponent } from '../common/alert.component';
import { RunsBindingDirective } from './remote-binding.directive';

declare var BHA_ADMIN:boolean;

@Component({
  selector: 'delete-run',
  template: `
    <ng-template [ngIf]="BhaAdmin" [ngIfElse]="cannotDelete">
      <a title="Delete run" href="#" (click)="$event.preventDefault();open(deleteRun)">
          <i class="material-icons">delete</i>
      </a>
      <ng-template #deleteRun let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Delete run?</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p class="lead">Are you sure you want to delete run {{runId}}?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="c('No')">No</button>
            <button type="button" class="btn btn-outline-dark" (click)="c('Yes')">Yes, delete it</button>
        </div>
      </ng-template>     
    </ng-template>
    <ng-template #cannotDelete></ng-template>
    `
})

export class DeleteRunComponent implements OnInit  {

    @Input()
    public runId:number;
    public BhaAdmin:boolean = BHA_ADMIN;
    @ViewChild(RunsBindingDirective) runsBindingDirective;
    @ViewChild(AlertCloseableComponent) alert;

    constructor(private modalService: NgbModal, private runsService: RunsService) {}
    
    ngOnInit() {
    }

    open(content) {
        this.modalService.open(content, {centered: true, ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          if (result === "Yes") {
            this.runsService.deleteRunById(this.runId).subscribe(
              val => {
                //val.status = 204;
                if (val === undefined)
                {
                  //console.log("DELETE successful", val);
                  this.alert.type = "success";
                  this.alert.message = "The run was deleted successfully.";
                  this.runsBindingDirective.rebind();
                }
                else
                {
                  //console.log("DELETE failed", val);
                  this.alert.type = "danger";
                  this.alert.message = "The run failed to delete.";
                }
              },
              response => {
                //response.status = 404;
                //debugger;
                //console.log("DELETE call in error", response);
                this.alert.type = "danger";
                this.alert.message = "The run failed to delete.";            
              },
              () => {
                //console.log("The DELETE observable is now completed.");
              });  
          }
          else {
            return;
          }
        }, (reason) => {
          //this.getDismissReason(reason)
        });
      }
}