<alert-closeable class="row justify-content-md-center" [visible]="false"></alert-closeable>
<div class="container-fluid">
    <div class="row">
        <div class="col">
            <kendo-grid runsBinding PersistGridSettings="BHA" id="RunsList" [pageSize]="20" [sortable]="true"
                [sort]="[{ 'dir': 'desc', 'field': 'SampleStartTime' }]" [resizable]="true" [filterable]="true"
                [pageable]="{ buttonCount: 5, info: true, type: 'numeric', pageSizes: true, previousNext: true }"
                [columnMenu]="true" [reorderable]="true">
                <ng-template kendoGridNoRecordsTemplate>Please wait while the data loads...</ng-template>
                <ng-template kendoGridToolbarTemplate>
                    <div class="row justify-content-between"
                        style="padding: 8px;background-color: #f6f6f6;margin: 0px;margin-right: -1px;border-style: ridge;border-block-color: inherit;border-width: thin;">
                        <div class="col-4" style="padding-left: 0px;">
                            <h5 class="lead">All Runs</h5>
                        </div>
                        <div class="col-4 text-right" style="margin-right: 4%;">
                            <div ngbDropdown class="d-inline-block">
                                <button class="btn btn-outline-secondary btn-sm" id="exportToExcelDropdownMenuLink"
                                    ngbDropdownToggle>Export to Excel</button>
                                <div ngbDropdownMenu aria-labelledby="exportToExcelDropdownMenuLink">
                                    <button (click)="onExportClick('visible')" class="dropdown-item">Current
                                        page</button>
                                    <button (click)="onExportClick('all')" class="dropdown-item">All pages</button>
                                </div>
                            </div>
                            <kendo-grid-excel>
                                <!-- <kendo-excelexport-column field="Longitude" title="Longitude"></kendo-excelexport-column>
                                <kendo-excelexport-column field="Latitude" title="Latitude"></kendo-excelexport-column> -->
                            </kendo-grid-excel>
                        </div>
                    </div>
                </ng-template>
                <kendo-grid-column field="ID" title="ID" width="30" filter="numeric" [hidden]="true">
                </kendo-grid-column>
                <kendo-grid-column field="RunUUID" title="Run UUID" width="30" [hidden]="true"></kendo-grid-column>
                <kendo-grid-column field="SampleStartTime" title="Start Time" width="80" filter="date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <a [routerLink]="['/run/details', dataItem.ID]">{{ dataItem.SampleStartTime | date:'short' }}
                            UTC</a> -->
                        <a style="cursor:pointer;" (click)="StartTimeDetails(dataItem.ID)">{{ dataItem.SampleStartTime |
                            date:'short' }} UTC</a>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="SensorBoardSerialNumber" title="Board Serial" width="75">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <a routerLink="/board/details/{{dataItem.SensorBoardSerialNumber}}">{{ dataItem.SensorBoardSerialNumber }}</a> -->
                        <a style="cursor:pointer;" (click)="sensorBrdDetails(dataItem.SensorBoardSerialNumber)">{{
                            dataItem.SensorBoardSerialNumber }}</a>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="BitMaterialNumber" title="Bit Material Number" width="50" filter="numeric">
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [decimals]="0"
                            [spinners]="false">
                        </kendo-grid-numeric-filter-cell>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a title="Marketing Sheet" href="http://hdbstech/MarketingSheets/{{dataItem.BitMaterialNumber}}"
                            target="_blank">{{ dataItem.BitMaterialNumber }}</a>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="BitSubSerialNumber" title="Bit Serial" width="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span style="cursor:pointer;" title="Offset Search"
                            (click)="offsetSearch(dataItem.BitMaterialNumber)">{{ dataItem.BitSubSerialNumber }}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="BitSize" title="Bit Size" width="30" filter="numeric">
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [decimals]="2"
                            [spinners]="false">
                        </kendo-grid-numeric-filter-cell>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="BitType" title="Bit Type" width="50"></kendo-grid-column>
                <kendo-grid-column field="TotalSampleTicks" title="Run Time (hr)" width="60" filter="numeric">
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [decimals]="0"
                            [spinners]="false">
                        </kendo-grid-numeric-filter-cell>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <kendo-lineargauge [pointer]="{ value: getSparkLineData(dataItem.TotalSampleTicks) }" [scale]="{ vertical: false, max: 200, min: 0, minorTicks: { visible: false } }"></kendo-lineargauge>                         -->
                        {{dataItem.TotalSampleTicks/10000000/60/60 | number:'1.0-2'}} hours
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="SamplesPerSecond" title="Samples Per Second" width="30" [hidden]="true"
                    filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="WellName" title="Well Name" width="80"></kendo-grid-column>
                <kendo-grid-column field="RunNumber" title="Run Number" width="60"></kendo-grid-column>
                <kendo-grid-column field="LatitudeLongitude" title="Lat/Long" width="80">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ng-container *ngIf="!(dataItem.Longitude =='' || dataItem.Latitude == '')">
                            <a title="Offset Search"
                                href="http://hdbstech/Wizdom/OffSetSearch?WellSearch=y&Latitude={{dataItem.Latitude}}&Longitude={{dataItem.Longitude}}"
                                target="_blank">{{ dataItem.Latitude }}, {{ dataItem.Longitude }}</a>
                        </ng-container>
                    </ng-template>
                </kendo-grid-column>
                <!-- <kendo-grid-column field="Longitude" title="Longitude" width="5" [locked]="true" [hidden]="true"></kendo-grid-column>
                <kendo-grid-column field="Latitude" title="Latitude" width="5" [locked]="true" [hidden]="true"></kendo-grid-column> -->
                <kendo-grid-column field="Comments" title="Comments" width="80"></kendo-grid-column>
                <kendo-grid-column field="PCSoftwareVersion" title="Software Version" width="80" [hidden]="true">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        v{{dataItem.PCSoftwareVersion.replace('Cerebro™ Electronic Data Capture at the Bit [Build:
                        ','').replace(']', '')}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="FirmwareVersion" title="Firmware Version" width="80" [hidden]="true">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.FirmwareVersion.replace('Cerebro(TM) ','')}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="OperatorName" title="Operator Name" width="80" [hidden]="true">
                </kendo-grid-column>
                <kendo-grid-column field="County" title="County" width="80" [hidden]="true"></kendo-grid-column>
                <kendo-grid-column field="State" title="State" width="80" [hidden]="true"></kendo-grid-column>
                <kendo-grid-column field="Country" title="Country" width="80" [hidden]="true"></kendo-grid-column>
                <kendo-grid-column field="LegalDescription" title="Legal Description" width="80" [hidden]="true">
                </kendo-grid-column>
                <kendo-grid-column field="RigName" title="Rig Name" width="80" [hidden]="true"></kendo-grid-column>
                <kendo-grid-column field="DatabaseLinkID" title="Database Link ID" width="80" [hidden]="true">
                </kendo-grid-column>

                <kendo-grid-column field="HardwareVersionName" title="Board Type" width="80" [hidden]="true">
                </kendo-grid-column>
                <kendo-grid-column field="HousingSerialNumber" title="Housing SN" width="30" [hidden]="true">
                </kendo-grid-column>

                <kendo-grid-column field="ClockResets" title="Clock Resets" width="30" [hidden]="true" filter="numeric">
                </kendo-grid-column>
                <kendo-grid-column field="DownholeOnlyTimePercent" title="Downhole %" width="30" [hidden]="true"
                    filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.DownholeOnlyTimePercent | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="IntervalTimeRecoveryPercent" title="Interval Rec. %" width="30"
                    [hidden]="true" filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.IntervalTimeRecoveryPercent | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="FootageRecoveryPercent" title="Footage Rec. %" width="30" [hidden]="true"
                    filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.FootageRecoveryPercent | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="TotalFileWriteErrors" title="File Write Errors" width="30" [hidden]="true"
                    filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="TotalSensorErrors" title="Sensor Errors" width="30" [hidden]="true"
                    filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="TotalParseErrors" title="Parse Errors" width="30" [hidden]="true"
                    filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="TotalSensorRows" title="Sensor Rows" width="30" [hidden]="true"
                    filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="TotalSurfaceRows" title="Surface Rows" width="30" [hidden]="true"
                    filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="TotalMergedRows" title="Merged Rows" width="30" [hidden]="true"
                    filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="PercentageAxialVibration" title="% Axial Vibe" width="30" [hidden]="true"
                    filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.PercentageAxialVibration | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="PercentageLateralVibration" title="% Lat. Vibe" width="30" [hidden]="true"
                    filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.PercentageLateralVibration | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="PercentageStickSlip" title="% Stick Slip" width="30" [hidden]="true"
                    filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.PercentageStickSlip | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="PercentageTorsionalVibration" title="% Tors. Vibe" width="30" [hidden]="true"
                    filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.PercentageTorsionalVibration | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="PercentageWhirlRadius" title="% Whirl" width="30" [hidden]="true"
                    filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.PercentageWhirlRadius | number:'1.0-0'}}%
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="CreatedBy" title="Processed By" width="80" [hidden]="true">
                </kendo-grid-column>

                <kendo-grid-column field="CreatedDate" title="Process Date" width="100" filter="date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.CreatedDate+'Z' | date:'short' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="MinCreatedDate" title="Creation Date" width="100" filter="date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.MinCreatedDate+'Z' | date:'short' }}
                    </ng-template>                    
                </kendo-grid-column>
                <kendo-grid-column field="FullSampleTicks" title="Recording Time (hr)" width="60" filter="numeric">
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <kendo-grid-numeric-filter-cell
                            [column]="column"
                            [filter]="filter"
                            [decimals]="0"
                            [spinners]="false">
                        </kendo-grid-numeric-filter-cell>
                    </ng-template>                    
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.FullSampleTicks/10000000/60/60 | number:'1.0-2'}} hours
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="FullFileWriteErrors" title="Max File Write Errors" width="30"  filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.FullFileWriteErrors | number:'1.0-0'}}
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="FullSensorErrors" title="Max Sensor Errors" width="30"  filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.FullSensorErrors | number:'1.0-0'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="FullParseErrors" title="Max Parse Errors" width="30"  filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.FullParseErrors | number:'1.0-0'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="FullClockResets" title="Max Clock Resets" width="30"  filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.FullClockResets | number:'1.0-0'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="ID" title="Actions" width="100" [filterable]="false" [sortable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <delete-run [runId]="dataItem.ID"></delete-run> -->
                        <!-- <a title="Run Details" routerLink="/run/details/{{dataItem.ID}}" target="_blank"><i
                                class="material-icons">search</i></a> -->
                        <a title="Run Details" style="cursor:pointer;" (click)="RunDetail(dataItem.ID)">
                            <i class="material-icons">search</i></a>
                        <!-- <a title="Board Details" routerLink="/board/details/{{dataItem.SensorBoardSerialNumber}}"><i
                                class="material-icons">memory</i></a> -->
                        <a title="Board Details" style="cursor:pointer;"
                            (click)="BoardDetail(dataItem.SensorBoardSerialNumber)">
                            <i class="material-icons">memory</i></a>
                        <i style="cursor:pointer;" title="Offset Search"
                            (click)="offsetSearch(dataItem.BitMaterialNumber)" class="material-icons">place</i>
                        <ng-container *ngIf="dataItem.DatabaseLinkID && dataItem.HasStatsFile>0">
                            <!-- <a title="Download Stats CSV" style="cursor:pointer;" href="{{BhaApi}}/StatsFile('{{dataItem.RunUUID}}')"><i class="material-icons">list</i></a> -->
                            <!-- <a title="Download Run Summary Report" style="cursor:pointer;" href="{{BhaApi}}/RunSummaryReport('{{dataItem.RunUUID}}')?u={{currentUserId}}"><i class="material-icons">picture_as_pdf</i></a> -->
                            <a title="Download Stats CSV" style="cursor:pointer;"
                                (click)="DownloadStatCSV(dataItem.RunUUID,dataItem.WellName,dataItem.BitSubSerialNumber)"><i
                                    class="material-icons">list</i></a>
                            <a title="Download Run Summary Report" style="cursor:pointer;"
                                (click)="DownloadPdf(dataItem.RunUUID, currentUserId,dataItem.WellName,dataItem.BitSubSerialNumber)"><i
                                    class="material-icons">picture_as_pdf</i></a>
                            <a title="Time Series Analysis" href="{{CerebroAppUrl}}/OPAnalysis/{{dataItem.RunUUID}}"
                                target="_blank"><i class="material-icons">show_chart</i></a>
                        </ng-container>
                    </ng-template>
                </kendo-grid-column>
                <!-- <div *kendoGridDetailTemplate="let dataItem">
                    <redundant-runs-list [parentRunId]="dataItem.ID"></redundant-runs-list>
                </div>   -->
                <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-info></kendo-pager-info>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                    <kendo-pager-page-sizes [pageSizes]="[20, 50, 100]"></kendo-pager-page-sizes>
                </ng-template>
            </kendo-grid>
        </div>
    </div>
    <!-- <div>
        <ng-container *ngFor="let file of filedownloadstatus; let i = index">
            <div class="progress" *ngIf="file.isDownloading">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':file.percentage+ '%'}">
                    {{file.percentage}}% {{file.fileName}}...</div>
            </div>
        </ng-container>
    </div> -->
    <div style="position:fixed;padding:7px;top:0;right:0;width:50%;z-index:999">
        <ng-container *ngFor="let file of filedownloadstatus; let i = index">
            <div class="progress" *ngIf="file.isDownloading">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':file.percentage+ '%'}">
                    {{file.percentage}}% {{file.fileName}}...</div>
            </div>
        </ng-container>
    </div>
</div>
<!-- {{this.persistGridService.states["runs-list"].filter|json}} -->
<!-- <button type="button" class="btn btn-outline-dark" (click)="refreshGrid()">Refresh Grid</button> -->