import { Component, OnInit, ViewChild } from '@angular/core';
import { BoardsBindingDirective } from '../remote-binding.directive';
import { BoardsService } from 'src/app/common/bha.service';
import { GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';

@Component({
  selector: 'board-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  @ViewChild(BoardsBindingDirective) boardsBindingDirective;
  @ViewChild(GridComponent) public grid: GridComponent;
  @ViewChild(ExcelComponent) public excel: ExcelComponent;  

  public maximumRunHours = 0;
  public runHoursBulletValueAxis: any;
  public maximumRuns = 0;
  public bulletValueAxis: any; //= {
  //     min: 0,
  //     max: this.maximumRuns,
  //     plotBands: [{
  //         from: 0, to: this.maximumRuns/2, color: "#00e504", opacity: 0.15
  //     }, {
  //         from: this.maximumRuns/2, to: this.maximumRuns*75, color: "#ffe807", opacity: 0.3
  //     }, {
  //         from: this.maximumRuns*.75, to: this.maximumRuns, color: "#e50000", opacity: 0.15
  //     }]
  // };  

  constructor(private boardService: BoardsService) { }

  ngOnInit() {
    const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
    if (userProfile == null) {
      setTimeout(() => {
        window.location.reload();      
      },800);
    }
    this.boardService.getMaximumRunCount().subscribe(r => {
      this.maximumRuns = r.data[0].Runs;
      this.bulletValueAxis = {
        min: 0,
        max: this.maximumRuns,
        plotBands: [{
            from: 0, to: this.maximumRuns/2, color: "#00e504", opacity: 0.15
        }, {
            from: this.maximumRuns/2, to: this.maximumRuns*.75, color: "#ffe807", opacity: 0.3
        }, {
            from: this.maximumRuns*.75, to: this.maximumRuns, color: "#e50000", opacity: 0.15
        }]
      };
    });   

    this.boardService.getMaximumHours().subscribe(r => {
      this.maximumRunHours = r.data[0].TotalRunHours;
      this.runHoursBulletValueAxis = {
        min: 0,
        max: this.maximumRunHours,
        plotBands: [{
            from: 0, to: this.maximumRunHours/2, color: "#00e504", opacity: 0.15
        }, {
            from: this.maximumRunHours/2, to: this.maximumRunHours*.75, color: "#ffe807", opacity: 0.3
        }, {
            from: this.maximumRunHours*.75, to: this.maximumRunHours, color: "#e50000", opacity: 0.15
        }]
      };
    });       
  }

  public onExportClick(option:string) {
    if (option === 'all')
    {
      this.excel.fileName = `All Boards.xlsx`;
      this.excel.fetchData = this.allData;
    }
    else
    {
      this.excel.fileName = `Boards.xlsx`;
      delete this.excel.fetchData;
    }
    this.grid.saveAsExcel();
  }
  
  public sensorBrdDetails(brSerialNo): void {
    window.open('/board/details/'+ brSerialNo);
  }

  public allData = (): Observable<any> => {
      return this.boardService.getAll({ filter: this.grid.filter, group: this.grid.group, skip: this.grid.skip, sort: this.grid.sort, take: this.grid.pageSize });
  }
}