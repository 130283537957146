import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardComponent }      from './dashboard.component';
import { TestComponent }      from './test/test.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { ChartsModule } from '@progress/kendo-angular-charts';

import { RunsOverTimeComponent } from './runsovertime.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartsModule,
  ],
  declarations: [DashboardComponent, TestComponent, RunsOverTimeComponent],
  providers: [ ]
})
export class DashboardModule { }