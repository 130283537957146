import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { FormsModule }              from '@angular/forms';

import { ListComponent }            from './list/list.component';
import { DetailsComponent }         from './details/details.component';

import { BoardsService, AggregateHistogramsService }          from '../common/bha.service'
import { BoardRoutingModule }       from './board-routing.module';

import { RunsByBoardSerialNumberBindingDirective, BoardsBindingDirective } from './remote-binding.directive';
import { GridModule, ExcelModule }  from '@progress/kendo-angular-grid';
import { ChartsModule }             from '@progress/kendo-angular-charts';
import { GaugesModule }             from '@progress/kendo-angular-gauges';
import { BhaCommonModule }          from '../common/common.module';

import {NgbDropdownModule}          from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    ExcelModule,
    ChartsModule,
    GaugesModule,
    BoardRoutingModule,
    BhaCommonModule,
    NgbDropdownModule
  ],
  declarations: [ListComponent, DetailsComponent, RunsByBoardSerialNumberBindingDirective, BoardsBindingDirective ],
  providers: [BoardsService, AggregateHistogramsService]
})
export class BoardModule { }
