/*
  Copyright 2018 Esri
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
*/

import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { loadModules } from 'esri-loader';
import esri = __esri;

@Component({
  selector: 'run-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  @Output() mapLoaded = new EventEmitter<boolean>();
  @ViewChild('mapViewNode') private mapViewEl: ElementRef;

  /**
   * @private _zoom sets map zoom
   * @private _center sets map center
   * @private _basemap sets type of map
   */
  private _zoom: number = 10;
  private _center: Array<number> = [0.1278, 51.5074];
  private _basemap: string = 'streets';

  @Input()
  set zoom(zoom: number) {
    this._zoom = zoom;
  }

  get zoom(): number {
    return this._zoom;
  }

  @Input()
  set center(center: Array<number>) {
    this._center = center;
  }

  get center(): Array<number> {
    return this._center;
  }

  @Input()
  set basemap(basemap: string) {
    this._basemap = basemap;
  }

  get basemap(): string {
    return this._basemap;
  }

  @Input()
  public plotCenter:boolean = false;  
  @Input()
  public wellName:string;
  @Input()
  public runNumber:string;  
  private markers = {}; //{Latitude:number, Longitude:number, WellName:string, RunNumber:string } = {Latitude:1, Longitude:1, WellName:'', RunNumber:''};
  public mapView: esri.MapView;

  constructor() { 
  }
  public addMarker(longitude:number, latitude:number, wellName:string, runNumber:string):boolean
  {
    if (isNaN(longitude)) longitude = -999;
    if (isNaN(latitude)) latitude = -999;

    if ((isNaN(longitude) ||  longitude === 0)
      ||  (isNaN(latitude) ||  latitude === 0))
    {
      return false;
    }
    
    let key = `${longitude}|${latitude}`;
    if (this.markers === undefined || this.markers[key]===undefined)
    {
      this.markers[key] = { Longitude:longitude, Latitude:latitude, WellName: wellName, RunNumber:runNumber };
    }

    return true;
  }

  async drawMarkers() {

    const [Graphic, Point, GraphicsLayer] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Point',
      'esri/layers/GraphicsLayer'
    ]);

    //var layer = new GraphicsLayer();

    Object.keys(this.markers).forEach(key => {
      if(this.markers[key].Longitude < -180
        || this.markers[key].Latitude < -90)
        return;

      var geometry = {
        type: "point", // autocasts as new Point()
        longitude: this.markers[key].Longitude,
        latitude: this.markers[key].Latitude
      };

      var symbol = {
        type: "simple-marker",   // autocasts as new SimpleMarkerSymbol()
        color: [226, 119, 40]  // RGB color values as an array
      };

      var attributes = {
        WellName: this.markers[key].WellName,
        RunNumber: this.markers[key].RunNumber
      };      

      var lineGraphic = new Graphic({
        geometry: geometry,
        symbol: symbol,
        attributes: attributes,
        popupTemplate: {
          title: "{wellName}",
          content: [{
            type: "fields",
            fieldInfos: [{
              fieldName: "WellName",
              label: "Well Name"
            }, {
              fieldName: "RunNumber",
              label: "Run Number"
            }]
          }]
        }
      });

      //layer.graphics.add(lineGraphic);
      this.mapView.graphics.add(lineGraphic);      
    });

    // this.mapView.map.add(layer);
    // layer.when(function(){
    //   let me = layer.queryExtent();
    //   debugger;
    //   return layer.queryExtent();
    // })
    // .then(function(response){
    //   debugger;
    //   this.maptView.goTo(response.extent);
    // });
    this.mapView.goTo(this.mapView.graphics).then( () => {
      this.mapView.zoom = this.mapView.zoom - 1;
    });
    //let calculatedCenter = this.getLatLngCenter(this.markers);
    //this.mapView.center = new Point(calculatedCenter[0], calculatedCenter[1]);
  }

  private rad2degr(rad):number { return rad * 180 / Math.PI; }
  private degr2rad(degr):number { return degr * Math.PI / 180; }
  
  /**
   * @param latLngInDeg array of arrays with latitude and longtitude
   *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
   *   [longtitude2] ...]
   *
   * @return array with the center latitude longtitude pairs in 
   *   degrees.
   */
  private getLatLngCenter(latLngInDegr):[number,number] {
    let sumX = 0;
      let sumY = 0;
      let sumZ = 0;
      let count = Object.keys(this.markers).length;
  
      Object.keys(this.markers).forEach(key => {
        let lat = this.degr2rad(latLngInDegr[key].Latitude);
        let lng = this.degr2rad(latLngInDegr[key].Longitude);
          // sum of cartesian coordinates
          sumX += Math.cos(lat) * Math.cos(lng);
          sumY += Math.cos(lat) * Math.sin(lng);
          sumZ += Math.sin(lat);
      });
  
      let avgX = sumX / count;
      let avgY = sumY / count;
      let avgZ = sumZ / count;
  
      // convert average x, y, z coordinate to latitude and longtitude
      let lng = Math.atan2(avgY, avgX);
      let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
      let lat = Math.atan2(avgZ, hyp);
  
      return ([this.rad2degr(lng), this.rad2degr(lat)]);
  }

  async initializeMap() {

    try {
      const [EsriMap, EsriMapView, Graphic] = await loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic'
      ]);

      // Set type of map
      const mapProperties: esri.MapProperties = {
        basemap: this._basemap
      };

      const map: esri.Map = new EsriMap(mapProperties);

      // Set type of map view
      const mapViewProperties: esri.MapViewProperties = {
        container: this.mapViewEl.nativeElement,
        center: this._center,
        zoom: this._zoom,
        map: map
      };

      this.mapView = new EsriMapView(mapViewProperties);

      // All resources in the MapView and the map have loaded.
      // Now execute additional processes
      this.mapView.when(() => {
        if (this.plotCenter)
        {
          var geometry = {
            type: "point", // autocasts as new Point()
            longitude: this._center[0],
            latitude: this._center[1]
          };
    
          var symbol = {
            type: "simple-marker",   // autocasts as new SimpleMarkerSymbol()
            color: [226, 119, 40]  // RGB color values as an array
          };
    
          var attributes = {
            WellName: this.wellName,
            RunNumber: this.runNumber
          };      
    
          var lineGraphic = new Graphic({
            geometry: geometry,
            symbol: symbol,
            attributes: attributes,
            popupTemplate: {
              title: "{wellName}",
              content: [{
                type: "fields",
                fieldInfos: [{
                  fieldName: "WellName",
                  label: "Well Name"
                }, {
                  fieldName: "RunNumber",
                  label: "Run Number"
                }]
              }]
            }
          });
    
          this.mapView.graphics.add(lineGraphic);        
        }
        this.mapLoaded.emit(true);
      });
    } catch (error) {
      this.mapLoaded.emit(false);
      console.log('Map failed to load: ' + error);
    }

    if ((isNaN(this.center[0]) ||  this.center[0] === 0)
      ||  (isNaN(this.center[1]) ||  this.center[1] === 0))
    {
      this.mapLoaded.emit(false);
      console.log(`Not generating map for ${this.center[0]}, ${this.center[1]}`);
    }
  }

  ngOnInit() {
    if (isNaN(this.center[0])) this.center[0] = 0;
    if (isNaN(this.center[1])) this.center[1] = 0;

    this.initializeMap();
  }

}
