import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent }     from  './dashboard.component'
import { TestComponent }     from  './test/test.component'

const runRoutes: Routes = [
  { path: 'dashboard',  component: DashboardComponent },
  { path: 'dashboard/test',  component: TestComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(runRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule { }