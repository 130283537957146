import { Pipe, PipeTransform } from '@angular/core';
/*
 * Converts ticks to hours
 * Usage:
 *   ticks | ticksToHours
 * To limit the hours to two decimals use two pipes:
 *   {{ 6546546546841 | ticksToHours | number : '1.2-2' }}
  * To limit the hours to no decimals use two pipes:
 *   {{ 6546546546841 | ticksToHours | number : '1.0-0' }}* 
*/
@Pipe({name: 'ticksToHours'})
export class TicksToHoursPipe implements PipeTransform {
  transform(ticks: number): number {
    let hours = ticks / 10000000 / 60 / 60;
    return hours;
  }
}

@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {

  private units = [
    'bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  transform(bytes: number = 0, precision: number = 2 ) : string {
    if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) return '?';

    let unit = 0;

    while ( bytes >= 1024 ) {
      bytes /= 1024;
      unit ++;
    }

    return bytes.toFixed( + precision ) + ' ' + this.units[ unit ];
  }
}