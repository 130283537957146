import { Directive, OnInit, OnDestroy, Input } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { BoardsService, RunsService } from '../common/bha.service';
import { State } from '@progress/kendo-data-query';
import { ActivatedRoute } from '@angular/router';

@Directive({
    selector: '[runsByBoardSerialNumberBinding]'
})
export class RunsByBoardSerialNumberBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
    @Input() boardSerialNumber: string;

    private serviceSubscription: Subscription;
    public boardId:any;
    public boardCount: any;
    constructor(private runs: RunsService, grid: GridComponent, private route: ActivatedRoute, private boardsService:BoardsService) {
        super(grid);
    }

    public ngOnInit(): void {
        this.route.paramMap.subscribe(r=>{
            this.boardId = r.get('id');
            this.runs.gridFilter(this.state,this.boardId);
       });
        this.serviceSubscription = this.runs.subscribe((result) => {
            this.grid.loading = false; // hide the loading indicator     
          this.grid.data = result;
          this.notifyDataChange(); // notify the grid that its data has changed
        });

        super.ngOnInit(); // do not forget to call the base implementation
    }

    public ngOnDestroy(): void {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    public rebind(): void {
        this.grid.loading = true; // optionally show loading indicator
        this.runs.queryForBoardSerialNumber({SensorBoardSerialNumber: this.boardSerialNumber }, this.state);
    }
}

@Directive({
    selector: '[boardsBinding]'
})
export class BoardsBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
    private serviceSubscription: Subscription;

    constructor(private boards: BoardsService, grid: GridComponent) {
        super(grid);
    }

    public ngOnInit(): void {
        this.grid.loading = true;
        this.serviceSubscription = this.boards.subscribe((result) => {
            this.grid.loading = false;
            this.grid.data = result;
            this.notifyDataChange();
        });
        this.boards.queryIt(this.state);
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    public rebind(): void {
        this.grid.loading = true;
        this.boards.queryIt(this.state);
    }
}
