import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map/map.component';
import { AlertCloseableComponent } from './alert.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { PersistGridSettingsDirective } from './persist-grid-settings-directive';
import { UploadComponent } from './upload/upload.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { FormsModule } from '@angular/forms';
import { FileSizePipe } from '../custom-pipes'

@NgModule({
  imports: [
    CommonModule,
    NgbAlertModule,
    UploadModule,
    ProgressBarModule,
    FormsModule
  ],
  declarations: [AlertCloseableComponent, MapComponent, PersistGridSettingsDirective, UploadComponent, FileSizePipe],
  exports: [AlertCloseableComponent, MapComponent, PersistGridSettingsDirective, UploadComponent ]
})
export class BhaCommonModule { }
