import { Component, Input, OnInit } from '@angular/core';
import { SeriesLabels } from '@progress/kendo-angular-charts';
import { BackendService } from '../common/backend.service';

@Component({
    selector: 'runs-over-time',
    providers: [BackendService],
    template: `
    <ng-template [ngIf]="RunDates.length>0" [ngIfElse]="noData">
        <kendo-chart>
            <kendo-chart-title text="Cerebro Runs Over Time"></kendo-chart-title>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item 
                    [title]="{ text: 'Date' }"
                    [categories]="RunDates"
                    [maxDivisions]="10"
                    [labels]="{ rotation: 'auto' }"
                    [justified]="{ value: true }">
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: '# of Runs' }"></kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-series>
                <kendo-chart-series-item type="area" [data]="RunningTotals" color="#CC0000" [labels]="seriesLabels"></kendo-chart-series-item>
                <kendo-chart-series-item type="area" [data]="Runs" color="#85B02E" [labels]="seriesLabels"></kendo-chart-series-item>
            </kendo-chart-series>            
        </kendo-chart>
    </ng-template>
    <ng-template #noData>
        <p class="lead">No data was found.</p>
    </ng-template>   
    `
})
export class RunsOverTimeComponent implements OnInit {

    public RunDates = [];
    public Runs = [];
    public RunningTotals = [];

    public seriesLabels: SeriesLabels = {
        visible: true, // Note that visible defaults to false
        padding: 3,
        font: 'bold 16px Arial, sans-serif',
        background: 'transparent'
      };

    constructor(private service: BackendService) {
    }

    public ngOnInit(): void { 
        this.service.getRunsOverTime().subscribe( response => {
            for (let i=0; i<response.length; i++)
            {
                this.RunDates.push(`${response[i].RunDate}`);
                this.Runs.push(response[i].Runs);
                this.RunningTotals.push(response[i].RunningTotal);
            }
        });  
     }
}