import { Directive, ViewChild, ElementRef, OnInit, OnDestroy, Input, EventEmitter } from '@angular/core';
//import { forEach } from '@angular/router/src/utils/collection';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GridComponent, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { newArray } from '@angular/compiler/src/util';
//import {UserSettings} from ;

declare var CURRENT_USER_ID: string;
declare var COMMON_API: string;
const isDate: Function = (value: any): boolean => value && value.getTime;

export class UserSettings {
    SettingsName: string;
    Settings: string;
    EmailID: string;
};
@Directive({
    selector: '[PersistGridSettings]'
})

export class PersistGridSettingsDirective implements OnInit, OnDestroy {

    @Input('PersistGridSettings') Name: string = 'GridSettings';
    public SettingsName: string;
    public El: GridComponent;
    public CurrentUserSettings: UserSettings;
    UserSettingsDataRetrieved: EventEmitter<any> = new EventEmitter();
    UserSettingsRetrieved: boolean = false;

    public Columns: any[] = [];
    public State: State = {};
    public GridpUserSettings: any;
    constructor(private http: HttpClient, private el?: GridComponent) {
        this.el.columnResize.subscribe((e) => this.UpdateSettings(e));
        this.el.columnReorder.subscribe((e) => this.UpdateSettings(e));
        this.el.columnVisibilityChange.subscribe((e) => this.UpdateSettings(e));
        this.el.dataStateChange.subscribe((e) => this.onDataStateChange(e));
        this.Columns = [];
    }

    public UpdateSettings(e) {
        //set Timeout is added because, the new reordered orderIndex(new orderIndex) is not active in the UI Thred, its refreshed only in timer
        setTimeout(() => {
            try {
                if (this.el && this.el.columns.length > 0) {
                    this.el.columns.forEach(element => {
                        let fieldName = this.GetColumnFieldName(element)
                        var column = this.GetColumn(fieldName);
                        //column.isVisible = element.isVisible;
                        column.hidden = element.hidden;
                        column.width = element.width;
                        column.orderIndex = element.orderIndex;
                    });
                }

                if (this.GridpUserSettings == null) {
                    this.GridpUserSettings = {
                        ID: 0,
                        UserId: CURRENT_USER_ID,
                        SettingsName: this.format("{0}:{1}-Settings", [this.Name, this.el.wrapper.nativeElement.id]),
                        Settings: ""
                    }
                }

                if (this.Columns.length > 0) {
                    let state: any = Object.assign({}, this.State);
                    //delete state.filter;
                    if (state.filter) {
                        for (let i = 0; i < state.filter.filters.length; i++) {
                            if (isDate(state.filter.filters[i].value)) {
                                state.filter.filters[i].datatype = "date";
                            }
                        }
                    }
                    var gridSettings = {
                        Columns: this.Columns,
                        PageSize: state.take,
                        State: state
                    }
                    this.GridpUserSettings.Settings = JSON.stringify(gridSettings);
                    this.UpdateGridSettings(JSON.stringify(gridSettings));
                }

            }
            catch (e) {
                var error = e;
            }
        }, 2000);
    }

    GetColumnFieldName(column) {
        var fieldName = column["field"];
        if (fieldName == undefined) fieldName = column["title"];
        return fieldName
    }

    GetColumn(field: string): any {
        let column = this.Columns.find(r => r.field == field);
        if (column == undefined) {
            column = {
                field: field
            };
            this.Columns.push(column);
        }
        return column;
    }

    onDataStateChange(state: State) {
        this.State = state;
        this.UpdateSettings(null);
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.SettingsName = this.format("{0}:{1}-Settings", [this.Name, this.el.wrapper.nativeElement.id]);
            this.GetUserSettings(this.SettingsName);
            this.UserSettingsDataRetrieved.subscribe(settings => {
                var retriveSetting: any;
                retriveSetting = settings.value && settings.value.find(x => x.EmailID == CURRENT_USER_ID && x.SettingsName == this.SettingsName);
                if (retriveSetting != null) {
                    this.GridpUserSettings = retriveSetting;
                    let gridSettings = JSON.parse(this.GridpUserSettings.Settings);

                    if (gridSettings.Columns) {
                        this.Columns = gridSettings.Columns;
                        this.Columns.forEach(item => {
                            this.el.columns.forEach(column => {
                                if (column["field"] == item.field || column["title"] == item.field) {
                                    if (item.width != undefined) column["width"] = item.width;
                                    //  if(item.isVisible) column["isVisible"] = item.isVisible;
                                    if (item.hidden != undefined) column["hidden"] = item.hidden;
                                    if (item.orderIndex != undefined) column["orderIndex"] = item.orderIndex;
                                }
                            });
                        });
                    }
                    if (gridSettings.State) {
                        if (gridSettings.State.filter) {
                            for (let i = 0; i < gridSettings.State.filter.filters.length; i++) {
                                if (gridSettings.State.filter.filters[i].datatype && gridSettings.State.filter.filters[i].datatype === "date") {
                                    delete gridSettings.State.filter.filters[i].datatype;
                                    gridSettings.State.filter.filters[i].value = new Date(gridSettings.State.filter.filters[i].value);
                                }
                            }
                        }
                        this.el.dataStateChange.emit(gridSettings.State);
                    }
                }
                else if (retriveSetting == null) {
                    retriveSetting = settings.value && settings.value.find(x => x.SettingsName == this.SettingsName)
                    this.GridpUserSettings = retriveSetting;
                    let gridSettings = JSON.parse(this.GridpUserSettings.Settings);

                    if (gridSettings.Columns) {
                        this.Columns = gridSettings.Columns;
                        this.Columns.forEach(item => {
                            this.el.columns.forEach(column => {
                                if (column["field"] == item.field || column["title"] == item.field) {
                                    column["width"] = 100;
                                    //  if(item.isVisible) column["isVisible"] = item.isVisible;
                                    if (item.hidden != undefined) column["hidden"] = false;
                                    //if (item.orderIndex != undefined) column["orderIndex"] = item.orderIndex;
                                }
                            });
                        });
                    }
                    this.State.skip = 0;
                    this.State.sort = [];
                    this.State.group = [];
                    this.State.sort.push({ field: "CreatedDate", dir: "desc" });
                    this.State.take = 20;

                    let state: any = Object.assign({}, this.State);
                    gridSettings.Columns = this.Columns;
                    gridSettings = {
                        Columns: this.Columns,
                        PageSize: state.take,
                        State: state
                    }
                    this.GridpUserSettings.Settings = JSON.stringify(gridSettings);
                    this.el.dataStateChange.emit(gridSettings.State);
                }
                //  if (settings.SettingsName == this.SettingsName) {
                //     this.GridpUserSettings = settings;
                //     let gridSettings = JSON.parse(this.GridpUserSettings.Settings);

                //     if (gridSettings.Columns) {
                //         this.Columns = gridSettings.Columns;
                //         this.Columns.forEach(item => {
                //             this.el.columns.forEach(column => {
                //                 if (column["field"] == item.field || column["title"] == item.field) {
                //                     if (item.width != undefined) column["width"] = item.width;
                //                     //  if(item.isVisible) column["isVisible"] = item.isVisible;
                //                     if (item.hidden != undefined) column["hidden"] = item.hidden;
                //                     if (item.orderIndex != undefined) column["orderIndex"] = item.orderIndex;
                //                 }
                //             });
                //         });
                //     }
                //     if (gridSettings.State) {
                //         if (gridSettings.State.filter) {
                //             for (let i = 0; i < gridSettings.State.filter.filters.length; i++) {
                //                 if (gridSettings.State.filter.filters[i].datatype && gridSettings.State.filter.filters[i].datatype === "date") {
                //                     delete gridSettings.State.filter.filters[i].datatype;
                //                     gridSettings.State.filter.filters[i].value = new Date(gridSettings.State.filter.filters[i].value);
                //                 }
                //             }
                //         }
                //         this.el.dataStateChange.emit(gridSettings.State);
                //     }
                // }
            });
        }, (2000));
    }

    ngOnDestroy(): void {
        this.UserSettingsDataRetrieved.unsubscribe();
    }

    GetUserSettings(settingsname: string) {
        const reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')
        });
        const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
        CURRENT_USER_ID = userProfile.mail;
        let urlGetUserSettings: string = COMMON_API + "/UserSettings?SettingsName='{0}'&EmailID=" + userProfile.mail;
        return this.http.get<Observable<any[]>>(this.format(urlGetUserSettings, [settingsname]), { headers: reqHeaders })
            .pipe(map((res) => res)).subscribe(result => {
                if (result) {
                    this.GridpUserSettings = result;
                    this.UserSettingsDataRetrieved.emit(result);
                }
                else {
                    //no user settings in the database
                    this.el.dataStateChange.emit({ filter: this.el.filter, group: this.el.group, skip: this.el.skip, sort: this.el.sort, take: this.el.pageSize });
                }

                this.UserSettingsRetrieved = true;
            });
        this.el.dataStateChange.emit({ filter: this.el.filter, group: this.el.group, skip: this.el.skip, sort: this.el.sort, take: this.el.pageSize });
        this.UserSettingsRetrieved = true;
    }

    UpdateGridSettings(settings: string) {
        let httpHeader = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')
            })
        };
        let urlUpdateUserSettings: string = COMMON_API + "/UserSettings";
        this.CurrentUserSettings =
        {
            EmailID: CURRENT_USER_ID,
            Settings: settings,
            SettingsName: this.SettingsName
        };
        this.http.post<any>(urlUpdateUserSettings, this.CurrentUserSettings, httpHeader)
            .pipe(map((res) => res['value'])).subscribe(result => { });
    }

    format(str, args: string[]) {
        return str.replace(/{[0-9]}/g, (matched) => args[parseInt(matched.replace(/[{}]/g, ''))]);
    };
}