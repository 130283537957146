import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { toODataString } from '@progress/kendo-data-query';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AppComponent } from '../app.component';
import { ActivatedRoute } from '@angular/router';


declare var BHA_API: string;
const isDate: Function = (value: any): boolean => value && value.getTime;
const toUTC = (date: Date) =>
    new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    ));

export interface FileDownloadStatus {
    fileName: string;
    isDownloading: boolean;
    percentage: number;
    runId: number;
}
export abstract class HdbsBhaService extends BehaviorSubject<GridDataResult> {
    public loading: boolean;
    BhaApi: string = BHA_API;
    public accessToken: string;
    private authService: MsalService;
    public state: any;
    public boardId: any;

    constructor(private http: HttpClient,
        protected tableName: string) {
        super(null);
    }

    public queryIt(state: any): void {
        this.fetch(this.tableName, state)
            .subscribe(x => super.next(x));
    }
    public gridFilter(state: any, boardId: any) {
        console.log('state', state);
        const state1 = { "skip": 0, "take": 20, "sort": [{ "field": "SampleStartTime" }], "group": [], "filter": { "filters": [{ "field": "SensorBoardSerialNumber", "operator": "eq", "value": boardId }], "logic": "and" } }
        return this.fetch(this.tableName, state1).subscribe(x => super.next(x));
    }

    protected fetch(tableName: string, state: any): Observable<GridDataResult> {
        this.state = state;
        let state2 = Object.assign({}, state); //Deep copy the state, because if we modify the original state the grid will rebind
        let queryStr = `${toODataString(state2)}&$inlinecount=allPages`;
        if (state2 && state2.filter) {
            for (let i = 0; i < state2.filter.filters.length; i++) {
                let item = state2.filter.filters[i];
                if (item.operator === "contains") {
                    queryStr = queryStr.replace("contains(" + item.field + ",'" + item.value + "')", "substringof('" + item.value + "', " + item.field + ") eq true");
                }

                if (isDate(item.value)) {
                    let date: string = JSON.stringify(toUTC(item.value)).replace(/"/g, "");
                    queryStr = queryStr.replace(date, "datetime'" + date + "'");
                }
            }
        }
        this.loading = true;
        this.accessToken = sessionStorage.getItem('accessToken');
        const reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + this.accessToken
        })
        return this.http
            .get(`${this.BhaApi}/${tableName}?${queryStr}`, { headers: reqHeaders })
            .pipe(
                map(response => (<GridDataResult>{
                    data: response['value'],
                    total: parseInt(response['odata.count'], 10),
                })),
                tap(() => this.loading = false)
            );
    }

    protected queryById<T>(id: number, extras: string = ''): Observable<T> {
        this.accessToken = sessionStorage.getItem('accessToken');
        const reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + this.accessToken
        })
        if (extras != '')
            extras = `?${extras}`;
        return this.http
            .get(`${this.BhaApi}/${this.tableName}(${id})${extras}`, { headers: reqHeaders })
            .pipe(
                map(response => (<any>
                    {
                        data: response,
                        total: 1 //todo: check for 0
                    }))
            );
    }

    protected queryByStringId<T>(id: string, extras: string = ''): Observable<T> {
        if (extras != '')
            extras = `?${extras}`;
        this.accessToken = sessionStorage.getItem('accessToken');
        const reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + this.accessToken
        })
        return this.http
            .get(`${this.BhaApi}/${this.tableName}('${id}')${extras}`, { headers: reqHeaders })
            .pipe(
                map(response => (<any>{
                    data: response,
                    total: 1 //todo: check for 0
                }
                ))
            );
    }

    protected query<T>(urlAppend: string, dataElementName: string = 'value'): Observable<T> {
        if (urlAppend.indexOf('?') < 0) {
            urlAppend = `${urlAppend}?$inlinecount=allPages`;
        }
        else {
            urlAppend = `${urlAppend}&$inlinecount=allPages`;
        }
        this.accessToken = sessionStorage.getItem('accessToken');
        const reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + this.accessToken
        })
        return this.http
            .get(`${this.BhaApi}/${this.tableName}${urlAppend}`, { headers: reqHeaders })
            .pipe(
                map(response => (<any>
                    {
                        data: dataElementName == '' ? response : response[dataElementName],
                        total: dataElementName == '' ? 1 : parseInt(response['odata.count'], 10)
                    }))
            );
    }

    protected deleteById(id: number): Observable<any> {
        //TBC
        this.accessToken = sessionStorage.getItem('accessToken');
        const reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + this.accessToken
        })
        return this.http.delete(`${this.BhaApi}/${this.tableName}(${id})`, { observe: 'response' });
    }
}

@Injectable()
export class RunsService extends HdbsBhaService {
    isDownload: boolean = false;
    constructor(http: HttpClient) {
        super(http, 'RunsWithDysfunction');
    }

    public queryItWithoutRedundantRuns(state: any): void {
        // Deep copy the state, because if we modify the
        // original state the grid will rebind.
        // let state2 = Object.assign({}, state);

        // // Filter out the redundant runs
        // let filter = { field: 'RedundantTo', operator: 'eq', value: null };
        // if (!state2.filter)
        // {
        //     let filters = [];
        //     filters.push(filter);
        //     state2.filter = {};
        //     state2.filter.filters = filters;
        // }
        // else
        // {
        //     //Only add RedundantTo if it isn't already there
        //     if (!state2.filter.filters.some(fil => {
        //             return (fil['field'] === 'RedundantTo');
        //         }))
        //         state2.filter.filters.push(filter);        
        // }      
        // this.fetch(this.tableName, state).subscribe(x => super.next(x));    
        if (sessionStorage.getItem('accessToken') == null)

            new Promise(() => setTimeout(() => this.fetch(this.tableName, state).subscribe(x => super.next(x)), 1000));

        else

            this.fetch(this.tableName, state).subscribe(x => super.next(x));
    }

    getDownloadFlag() {
        return this.isDownload;
    }
    setDownloadFlag(download) {
        this.isDownload = download;
    }

    public queryForBoardSerialNumber({ SensorBoardSerialNumber }: { SensorBoardSerialNumber: string }, state?: any): void {
        this.queryIt(Object.assign({}, state, {
            filter: {
                filters:
                    [
                        { field: 'SensorBoardSerialNumber', operator: 'eq', value: SensorBoardSerialNumber }
                        //{ field: 'RedundantTo', operator: 'eq', value: null }, //filter out redundant runs
                    ],
                logic: 'and'
            }
        }));
    }

    public getAllForExportToExcel(state: any): Observable<any> {

        // // Deep copy the state, because if we modify the
        // // original state the grid will rebind.
        // let state2 = Object.assign({}, state);

        // // Delete the take so that we get all pages
        // delete state2.take;

        // // Filter out the redundant runs
        // let filter = { field: 'RedundantTo', operator: 'eq', value: null };
        // if (!state2.filter)
        // {
        //     let filters = [];
        //     filters.push(filter);
        //     state2.filter = {};
        //     state2.filter.filters = filters;
        // }
        // else
        // {
        //     state2.filter.filters.push(filter);
        // }
        return this.fetch('RunsWithDysfunction', state);
    }

    public queryForRedundantRuns(parentRunId: number, state?: any): void {
        this.queryIt(Object.assign({}, state, {
            filter: {
                filters: [{
                    field: 'RedundantTo', operator: 'eq', value: parentRunId
                }],
                logic: 'and'
            }
        }));
    }

    public getRunById(id: number): Observable<any> {
        return this.queryById<any>(id);
    }

    public deleteRunById(id: number): Observable<any> {
        return this.deleteById(id);
    }

    getRedundantRunsByRunId(id: number): Observable<any> {
        return this.query<any>(`?$filter=RedundantTo eq ${id}&$orderby=ID desc`);
    }

    getRunsByBoardSerialNumber(serialNumber: string): Observable<any> {
        return this.query<any>(`?$filter=SensorBoardSerialNumber eq '${serialNumber}'&$orderby=ID desc`);
    }

    // getTotalFileWriteErrorsByBoardSerialNumber(serialNumber: string): Observable<any> {
    //     return this.query<any>(`?$filter=SensorBoardSerialNumber eq '${serialNumber}'&$select=TotalFileWriteErrors&$orderby=SampleStartTime asc`);
    // }

    // getTotalSensorErrorsByBoardSerialNumber(serialNumber: string): Observable<any> {
    //     return this.query<any>(`?$filter=SensorBoardSerialNumber eq '${serialNumber}'&$select=TotalSensorErrors&$orderby=SampleStartTime asc`);
    // }

    // getTotalParseErrorsByBoardSerialNumber(serialNumber: string): Observable<any> {
    //     return this.query<any>(`?$filter=SensorBoardSerialNumber eq '${serialNumber}'&$select=TotalParseErrors&$orderby=SampleStartTime asc`);
    // }

}

@Injectable()
export class HistogramsService extends HdbsBhaService {
    constructor(http: HttpClient) { super(http, 'Histogram'); }

    queryAll(st?: any): Observable<GridDataResult> {
        const state = Object.assign({}, st);
        delete state.skip;
        delete state.take;

        return this.fetch(this.tableName, state);
    }

    public getHistogramByRunId(id: number, type: string): Observable<any> {
        return this.query<any>(`?$filter=Type eq '${type}' and RunID eq ${id}&$orderby=BucketMin`);
    }
}

@Injectable()
export class AggregateHistogramsService extends HdbsBhaService {
    constructor(http: HttpClient) { super(http, 'AggregateHistogram'); }

    queryAll(st?: any): Observable<GridDataResult> {
        const state = Object.assign({}, st);
        delete state.skip;
        delete state.take;

        return this.fetch(this.tableName, state);
    }

    public getTemperatureHistogramByBoardSerialNumber(serialNumber: string): Observable<any> {
        return this.query<any>(`?$filter=SerialNumber eq '${serialNumber}' and Type eq 'Temperature_deg_C'&$orderby=BucketMin`);
    }
}

@Injectable()
export class MaterialNumberService {

    _http: HttpClient;

    constructor(http: HttpClient) {
        this._http = http;
    }

    public getMaterialNumberBySerialNumber(serialNumber: string): Observable<any> {
        return this._http.get(`http://hdbstech/dbsservices/odata/MarketingSheets/LookupMaterialNumbers('${serialNumber}')/MaterialsBySerial`, { observe: 'response' });
    }
}

@Injectable()
export class BoardsService extends HdbsBhaService {
    constructor(http: HttpClient) { super(http, 'Board'); }

    queryAll(st?: any): Observable<GridDataResult> {
        const state = Object.assign({}, st);
        delete state.skip;
        delete state.take;
        return this.fetch(this.tableName, state);
    }

    public getAll(state: any): Observable<any> {
        let state2 = Object.assign({}, state); //Deep copy the state, because if we modify the original state the grid will rebind
        delete state2.take;
        return this.fetch('Board', state2);
    }

    public getBoardBySerialNumber(serialNumber: string): Observable<any> {
        return this.queryByStringId<any>(serialNumber);
    }

    public getMaximumRunCount(): Observable<any> {
        return this.query<any>(`?$top=1&$select=Runs&$orderby=Runs desc`);
    }
    public getMaximumHours(): Observable<any> {
        return this.query<any>(`?$top=1&$select=TotalRunHours&$orderby=TotalRunHours desc`);
    }
}