<hr/>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <p style="text-align: left;">
                    For questions, comments, or access requests please submit an incident <a target="_blank" href="http://hdbstech/support/Incidents/New">here</a>.
                </p>
            </div>
            <div class="col">
                <div class="bg-Drill_Bits_Horz text-center"></div>
            </div>
            <div class="col">
                <p style="text-align: right;">
                    &copy; {{ today | date:'y' }} - Halliburton Drill Bits and Services
                </p>
            </div>
        </div>
    </div>
</footer>