import { NgModule }                 from "@angular/core";
import { RouterModule, Routes }     from '@angular/router';
import { PageNotFoundComponent }    from './page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { HeaderComponent } from './common-ui/header/header.component'
import { MsalGuard } from '@azure/msal-angular';
import { ListComponent } from './run/list/list.component';

const appRoutes : Routes =[   
    {path: '', component: ListComponent , canActivate: [MsalGuard]},     
    {path: 'profile', component: ProfileComponent , canActivate: [MsalGuard]}, 
    { path: '', redirectTo: '/runs', pathMatch: 'full', canActivate: [MsalGuard]},
    { path: '**', component: PageNotFoundComponent },   
]
const isIframe = window !== window.parent && !window.opener;
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes,
        {
            initialNavigation: !isIframe ? 'enabled' : 'disabled'
        })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {}