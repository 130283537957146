import { Component, Inject, OnInit, } from '@angular/core';
import { HeaderData, UserData, MenuItem } from './HeaderData';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {InteractionType,} from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';

declare var CURRENT_USER_DISPLAYNAME:string;
declare var CURRENT_USER_ID:number;
declare var DATCITOOLBOX_URL:string;

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css',
  './notifications.css']
})

export class HeaderComponent implements OnInit {
  currentUserDisplayName:string = CURRENT_USER_DISPLAYNAME;
  datcitoolboxUrl: string = '';
  currentUserId:number = CURRENT_USER_ID; 
  profile! :ProfileType;
  private _destroying$: any;
  apiResponse : string;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient
  ) { }

      
  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        sessionStorage.setItem('userProfile', JSON.stringify(profile));
      });
  }

  ngOnInit() {
    this.datcitoolboxUrl = DATCITOOLBOX_URL;
   this.getProfile();
  }

  headerData = new HeaderData(    
    new UserData(this.currentUserDisplayName, this.currentUserId),
    [
      new MenuItem("Runs", "run/list", null),
      new MenuItem("Boards", "board/list", null),
      new MenuItem(null,null,null,true),
      new MenuItem("ADesc", null, DATCITOOLBOX_URL+"/adesc"),
      new MenuItem("Ops Desktop", null, DATCITOOLBOX_URL+"/ops"),
      new MenuItem("Wizdom", null, DATCITOOLBOX_URL+"/Wizdom"),
      new MenuItem(null,null,null,true),
      new MenuItem("Incident Tracker", null, DATCITOOLBOX_URL+"/IncidentTracker")
    ]);

    logout() {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.authService.logoutPopup({
          postLogoutRedirectUri: "/",
          mainWindowRedirectUri: "/"
        });
      } else {
        this.authService.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }
    }


    // getProfile() {
    //   this.http.get(GRAPH_ENDPOINT)
    //     .subscribe(resp => {
    //       this.apiResponse = JSON.stringify(resp);
    //       console.log(this.apiResponse);
    //     });
    // }
    
    ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
    }

} 
