<div *ngIf="board$" class="container-fluid">
  <div class="row">
    <div class="col"><h2>Board ({{board$.SerialNumber}}) Details</h2></div>
  </div>
  <hr/>
  <div class="row">
    <div class="col-8">
      <dl class="row">
          <dt class="col-sm-3">Serial Number</dt>
          <dd class="col-sm-9">{{ board$.SerialNumber }}</dd>      
          <dt class="col-sm-3">Runs</dt>
          <dd class="col-sm-9">
            <dl class="row">
              <dt class="col-sm-4">First Run Date</dt>
              <dd class="col-sm-8">{{ board$.FirstRunDate | date:'short' }}</dd>
              <dt class="col-sm-4">Last Run Date</dt>
              <dd class="col-sm-8">{{ board$.LastRunDate | date:'short' }}</dd>
              <dt class="col-sm-4">Total Runs</dt>
              <dd class="col-sm-8">{{board$.Runs | number:'1.0-0' }} runs</dd>  
              <dt class="col-sm-4">Total Run Length (in hours)</dt>
              <dd class="col-sm-8">{{board$.TotalRunHours | number:'1.0-0' }} hours</dd>        
              <dt class="col-sm-4">Average Hours per Run (in hours)</dt>
              <dd class="col-sm-8">{{board$.TotalRunHours / board$.Runs | number:'1.0-1' }} hours</dd>                
            </dl>      
          </dd>    
          <!-- <dt class="col-sm-3">Errors</dt>
          <dd class="col-sm-9">
            <dl class="row">
                <dt class="col-sm-4">File Write Errors</dt>
                <dd class="col-sm-8"><kendo-sparkline [data]="sparkLineFileWriteErrors"></kendo-sparkline> {{board$.TotalFileWriteErrors | number:'1.0-0' }} errors ({{board$.TotalFileWriteErrors/board$.Runs | number:'1.0-0' }}/run)</dd>  
                <dt class="col-sm-4">Sensor Errors</dt>
                <dd class="col-sm-8"><kendo-sparkline [data]="sparkLineSensorErrors"></kendo-sparkline> {{board$.TotalSensorErrors | number:'1.0-0' }} errors ({{board$.TotalSensorErrors/board$.Runs | number:'1.0-0' }}/run)</dd>  
                <dt class="col-sm-4">Parse Errors</dt>
                <dd class="col-sm-8"><kendo-sparkline [data]="sparkLineParseErrors"></kendo-sparkline> {{board$.TotalParseErrors | number:'1.0-0' }} errors ({{board$.TotalParseErrors/board$.Runs | number:'1.0-0' }}/run)</dd>                      
            </dl>      
          </dd> -->
        </dl>
    </div>
    <div class="col-4">
        <ng-template [ngIf]="mapLoaded" [ngIfElse]="noMap">
            <run-map 
                [center]="[-97.58,35.7]"
                [basemap]="'streets'"
                [zoom]="'6'"
                [wellName]="''"
                [runNumber]="''"
                [plotCenter]="false"
                (mapLoaded)="mapLoadedEvent($event)"></run-map>            
        </ng-template>
        <ng-template #noMap><p class="lead">Unable to load the map due to missing or inaccurate location data for this run.</p></ng-template>      
    </div>
  </div>        
  <hr/>  
  <div class="row">
      <div class="col">
          <div class="col">
              <div class="card">
                  <kendo-chart>
                      <kendo-chart-title text="Board Temperature (&deg;C)"></kendo-chart-title>
                      <kendo-chart-category-axis>
                          <kendo-chart-category-axis-item 
                            [title]="{ text: 'Temperature &deg;C' }"
                            [categories]="['-40&deg;', '-30&deg;', '-20&deg;', '-10&deg;', '0&deg;', '10&deg;', '20&deg;','30&deg;','40&deg;','50&deg;','60&deg;','70&deg;','80&deg;', '90&deg;','100&deg;','110&deg;','120&deg;','130&deg;','140&deg;']"
                            [maxDivisions]="10"
                            [labels]="{ rotation: 'auto' }"
                            [justified]="{ value: true }">
                          </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                      <kendo-chart-value-axis>
                          <kendo-chart-value-axis-item [title]="{ text: 'Hours' }"></kendo-chart-value-axis-item>
                      </kendo-chart-value-axis>
                      <kendo-chart-series>
                        <kendo-chart-series-item type="column" [gap]=".25" [spacing]=".25" [data]="histogramData">
                        </kendo-chart-series-item>
                      </kendo-chart-series>
                  </kendo-chart>    
              </div>
          </div>
      </div>          
      <div class="col">
        <div class="card">
            <div class="card-body">
                <ng-template [ngIf]="boardSn" [ngIfElse]="noRuns">
                    <kendo-grid
                      runsByBoardSerialNumberBinding
                      [boardSerialNumber]="boardSn"
                      PersistGridSettings="BHA"
                      id="RunsByBoardList"
                      [height]="400"
                      [pageSize]="20"
                      [sortable]="true"
                      [sort]="[{ 'dir': 'desc', 'field': 'SampleStartTime' }]"
                      [resizable]="true"
                      [filterable]="true"
                      [pageable]="{ buttonCount: 5, info: true, type: 'numeric', pageSizes: true, previousNext: true }"
                      [columnMenu]="true"
                      [reorderable]="true">
                      <ng-template kendoGridNoRecordsTemplate>Please wait while the data loads...</ng-template>
                      <ng-template kendoGridToolbarTemplate>
                          <div class="row justify-content-between" style="padding-right:30px;">
                              <div class="col-4"><h5 class="lead">All Runs</h5></div>
                              <div class="col-4 text-right">
                                  <div ngbDropdown class="d-inline-block">
                                      <button class="btn btn-outline-secondary btn-sm" id="exportToExcelDropdownMenuLink" ngbDropdownToggle>Export to Excel</button>
                                      <div ngbDropdownMenu aria-labelledby="exportToExcelDropdownMenuLink">
                                          <button (click)="onExportClick('visible')" class="dropdown-item">Current page</button>
                                          <button (click)="onExportClick('all')" class="dropdown-item">All pages</button>
                                      </div>
                                  </div>                            
                                  <kendo-grid-excel>
                                      <!-- <kendo-excelexport-column field="Longitude" title="Longitude"></kendo-excelexport-column>
                                      <kendo-excelexport-column field="Latitude" title="Latitude"></kendo-excelexport-column> -->
                                  </kendo-grid-excel>
                              </div>
                          </div>
                      </ng-template>
                      <kendo-grid-column field="ID" title="ID" width="30" filter="numeric" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="SampleStartTime" title="Start Time" width="100" filter="date">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <a [routerLink]="['/run/details', dataItem.ID]">{{ dataItem.SampleStartTime | date:'short' }}</a>
                          </ng-template>
                      </kendo-grid-column>                
                      <kendo-grid-column field="SensorBoardSerialNumber" title="Board Serial" width="75" [hidden]="true">
                          <ng-template kendoGridCellTemplate let-dataItem>
                                  <a routerLink="/board/details/{{dataItem.SensorBoardSerialNumber}}">{{ dataItem.SensorBoardSerialNumber }}</a>
                          </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="BitMaterialNumber" title="Bit Material Number" width="50" filter="numeric" [hidden]="true">
                          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                              <kendo-grid-numeric-filter-cell
                                  [column]="column"
                                  [filter]="filter"
                                  [decimals]="0"
                                  [spinners]="false">
                              </kendo-grid-numeric-filter-cell>
                          </ng-template>
                          <ng-template kendoGridCellTemplate let-dataItem >
                              <a title="Marketing Sheet" href="http://hdbstech/MarketingSheets/{{dataItem.BitMaterialNumber}}" target="_blank">{{ dataItem.BitMaterialNumber }}</a>
                          </ng-template>                    
                      </kendo-grid-column>
                      <kendo-grid-column field="BitSubSerialNumber" title="Bit Serial" width="50" [hidden]="true">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                  <span style="cursor:pointer;" title="Offset Search" (click)="offsetSearch(dataItem.BitMaterialNumber)">{{ dataItem.BitSubSerialNumber }}</span>
                              </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="BitSize" title="Bit Size" width="30" filter="numeric" [hidden]="true">
                          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                              <kendo-grid-numeric-filter-cell
                                  [column]="column"
                                  [filter]="filter"
                                  [decimals]="2"
                                  [spinners]="false">
                              </kendo-grid-numeric-filter-cell>
                          </ng-template>                         
                      </kendo-grid-column>
                      <kendo-grid-column field="BitType" title="Bit Type" width="50" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="TotalSampleTicks" title="Run Time (hr)" width="60" filter="numeric">
                          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                              <kendo-grid-numeric-filter-cell
                                  [column]="column"
                                  [filter]="filter"
                                  [decimals]="0"
                                  [spinners]="false">
                              </kendo-grid-numeric-filter-cell>
                          </ng-template>                    
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <!-- <kendo-lineargauge [pointer]="{ value: getSparkLineData(dataItem.TotalSampleTicks) }" [scale]="{ vertical: false, max: 200, min: 0, minorTicks: { visible: false } }"></kendo-lineargauge>                         -->
                              {{dataItem.TotalSampleTicks/10000000/60/60 | number:'1.0-2'}} hours
                          </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="WellName" title="Well Name" width="80"></kendo-grid-column>
                      <kendo-grid-column field="RunNumber" title="Run Number" width="60" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="LatitudeLongitude" title="Lat/Long" width="80" [hidden]="true">
                          <ng-template kendoGridCellTemplate let-dataItem >
                              <ng-container *ngIf="!(dataItem.Longitude =='' || dataItem.Latitude == '')">
                                  <a title="Offset Search" href="http://hdbstech/Wizdom/OffSetSearch?WellSearch=y&Latitude={{dataItem.Latitude}}&Longitude={{dataItem.Longitude}}" target="_blank">{{ dataItem.Latitude }}, {{ dataItem.Longitude }}</a>
                              </ng-container>
                          </ng-template>
                      </kendo-grid-column>
                      <!-- <kendo-grid-column field="Longitude" title="Longitude" width="5" [locked]="true" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="Latitude" title="Latitude" width="5" [locked]="true" [hidden]="true"></kendo-grid-column> -->
                      <kendo-grid-column field="Comments" title="Comments" width="80"></kendo-grid-column>
                      <kendo-grid-column field="PCSoftwareVersion" title="PC Software Version" width="80" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="FirmwareVersion" title="Firmware Version" width="80" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="OperatorName" title="Operator Name" width="80" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="County" title="County" width="80"></kendo-grid-column>
                      <kendo-grid-column field="State" title="State" width="80"></kendo-grid-column>
                      <kendo-grid-column field="Country" title="Country" width="80" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="LegalDescription" title="Legal Description" width="80" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="RigName" title="Rig Name" width="80" [hidden]="true"></kendo-grid-column>
                      <kendo-grid-column field="CreatedDate" title="Process Date" width="100" filter="date" [hidden]="true">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                      {{ dataItem.CreatedDate | date:'short' }}
                              </ng-template>                    
                      </kendo-grid-column>
                      <kendo-grid-column field="ID" title="Actions" width="80" [filterable]="false" [sortable]="false">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <a title="Run Details" routerLink="/run/details/{{dataItem.ID}}"><i class="material-icons">search</i></a>
                              <a title="Board Details" routerLink="/board/details/{{dataItem.SensorBoardSerialNumber}}"><i class="material-icons">memory</i></a>
                              <i style="cursor:pointer;" title="Offset Search" (click)="offsetSearch(dataItem.BitSubSerialNumber)" class="material-icons">place</i>
                          </ng-template>
                      </kendo-grid-column>                
                      <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                          <kendo-pager-info></kendo-pager-info>
                          <kendo-pager-next-buttons></kendo-pager-next-buttons>
                          <kendo-pager-page-sizes [pageSizes]="[20, 50, 100]"></kendo-pager-page-sizes>
                       </ng-template>                              
                  </kendo-grid>    
                </ng-template>
                <ng-template #noRuns>
                  <p class="lead">No runs were found for this board.</p>
                </ng-template>
            </div>
        </div>
      </div>
    </div> 
    <div class="row">
    </div>  
</div>
