import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, Injectable, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import {
  Router,
  UrlSerializer,
  DefaultUrlSerializer,
  UrlTree
} from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { CommonUiModule } from "./common-ui/common-ui.module";
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BoardModule } from './board/board.module';
import { RunModule } from './run/run.module';
import { GraphsModule } from './graphs/graphs.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './profile/profile.component';

//import 'hammerjs';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { UploadModule } from '@progress/kendo-angular-upload';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './services/errorhandler.service';
import { HttpErrorInterceptor } from './services/httpError';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
declare var BhaWebsiteUrl: string;
declare var AppClientId: string;

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    return super.parse(url.toLowerCase());
  }
}
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  console.log('MSAL STarted');
  return new PublicClientApplication({
    auth: {
      clientId: AppClientId,
      //clientId: 'e7a6ec14-281e-4357-a02a-b58bf252a6bb', 
      //clientId: '4d33b4b6-ef67-4e8f-9d21-3c160eeb21da',      
      authority: 'https://login.microsoftonline.com/organizations',
      //redirectUri: 'http://localhost:4200'
      //redirectUri: 'https://dbs-cebro-dev-scus-app-bha.azurewebsites.net'
      redirectUri: BhaWebsiteUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false, // set to true for IE 11
    },

    system: {
      allowRedirectInIframe: true,
      tokenRenewalOffsetSeconds: 300,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

// export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//     protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>([
//       ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
//       //["https://dbs-cebro-dev-scus-app-dbservices.azurewebsites.net/odata/Bha", [
//           ["http://localhost/:18402/odata/Bha", [
//         "access_as_user",
//         // "all.scope",
//         {
//           httpMethod: "GET",
//           scopes: ["read.scope"]
//         },
//         {
//           httpMethod: "POST",
//           scopes: ["info.scope"]
//         }
//       ]]
//     ])
//   };
// }
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    CommonUiModule,
    BoardModule,
    RunModule,
    DashboardModule,
    GraphsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GaugesModule,
    UploadModule,
    HttpClientModule,
    MsalModule
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    // { provide: HTTP_INTERCEPTORS, 
    //   useClass: HttpErrorInterceptor, 
    //   multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  BhaWebsiteUrl: string = BhaWebsiteUrl;
  AppClientId: string = AppClientId;
  // Diagnostic only: inspect router configuration
  constructor() {
    //router: Router
    //console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}