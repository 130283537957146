import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListComponent }        from  './list/list.component'
import { DetailsComponent }     from  './details/details.component'

const boardRoutes: Routes = [
  { path: 'boards',  component: ListComponent },
  { path: 'board/list',  component: ListComponent },
  { path: 'board/details/:id',  component: DetailsComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(boardRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class BoardRoutingModule { }