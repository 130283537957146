export class UserData {
    constructor(
        public DisplayName:string,
        public UserId:number) {}
}

export class MenuItem {
    constructor(
        public DisplayName:string,
        public Route:string,
        public Url:string,
        public Separator=false) {}
}

export class HeaderData {
    constructor(
        public UserData:UserData,
        public MenuItems:MenuItem[]) {}
}