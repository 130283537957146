import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListComponent }     from  './list/list.component'
import { DetailsComponent }     from  './details/details.component'
import { DeleteRunComponent }     from  './delete-run.component'

const runRoutes: Routes = [
  { path: 'runs',  component: ListComponent },
  { path: 'run/list',  component: ListComponent },
  { path: 'run/details/:id',  component: DetailsComponent },
  { path: 'run/delete/:id',  component: DeleteRunComponent, outlet: 'modal' }
];

@NgModule({
  imports: [
    RouterModule.forChild(runRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RunRoutingModule { }