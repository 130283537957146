import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'

export interface Run {
    runId: number,
    wellName: string
  }

  export interface Download {
    isDownload: boolean
  }

@Injectable()
export class RunService {
  constructor(private http: HttpClient) {}
  
  getAllRuns(): Observable<Run[]> {
    return this.http.get<Run[]>('http://localhost:8000/api/bha/runs')
  }

  getRun(runId: number): Observable<Run> {
    return this.http.get<Run>('http://localhost:8000/api/bha/run/' + runId)
  }

  insertRun(run: Run): Observable<Run> {
    return this.http.post<Run>('http://localhost:8000/api/bha/run/', run)
  }

  updateRun(run: Run): Observable<void> {
    return this.http.put<void>(
      'http://localhost:8000/api/bha/run/' + run.runId,
      run
    )
  }

  deleteRun(runId: number) {
    return this.http.delete('http://localhost:8000/api/bha/run/' + runId)
  }
}