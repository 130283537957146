import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';

import { RunsService } from '../../common/bha.service';

@Component({
    selector: 'redundant-runs-list',
    providers: [RunsService],
    template: `
      <kendo-grid
          [data]="view | async"
          [pageSize]="5"
          [skip]="skip"
          [pageable]="true"
          [scrollable]="'none'"
          [columnMenu]="true"
          [reorderable]="true"          
          (pageChange)="pageChange($event)"
        >
        <ng-template kendoGridToolbarTemplate>
            <div class="row justify-content-between">
                <div class="col-4"><h5 class="lead">Redundant Runs</h5></div>
                <div class="col-4 text-right">
                    <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
                    <kendo-grid-excel></kendo-grid-excel>
                </div>
            </div>
        </ng-template>        
      <kendo-grid-column field="ID" title="ID" width="120">
      </kendo-grid-column>
      <kendo-grid-column field="Comments" title="Comments">
      </kendo-grid-column>
      <kendo-grid-column field="CreatedDate" title="Download Date">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.CreatedDate | date:'short' }}
        </ng-template>         
      </kendo-grid-column>
      <kendo-grid-column field="ID" title="Actions" width="80" [filterable]="false" [sortable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <delete-run [runId]="dataItem.ID"></delete-run>
      </ng-template>
  </kendo-grid-column>           
    </kendo-grid>
  `
})
export class RedundantRunsListComponent implements OnInit {

    /**
     * The parent run ID for which the redundant runs list is displayed
     */
    @Input() public parentRunId: number;

    public view: Observable<GridDataResult>;
    public skip = 0;

    constructor(private service: RunsService) { }

    public ngOnInit(): void {
        this.view = this.service;

        this.service.queryForRedundantRuns(this.parentRunId, { skip: this.skip, take: 5 });
    }

    public pageChange({ skip, take }: PageChangeEvent): void {
        this.skip = skip;
        this.service.queryForRedundantRuns(this.parentRunId, { skip, take });
    }
}