import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { Location } from '@angular/common';
import { RunsService, MaterialNumberService, FileDownloadStatus } from '../../common/bha.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
 import * as FileSaver from 'file-saver';

import { SatoriService, SatoriFile } from 'src/app/common/satori.service';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
declare var BHA_API: string;
declare var CURRENT_USER_ID: number;

@Component({
  selector: 'run-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class DetailsComponent implements OnInit {
  BhaApi: string = BHA_API;
  currentUserId: number = CURRENT_USER_ID;
  BhaApiOld: string = BHA_API;
  private runId: number = 0;
  public run$: Observable<any>;
  public bit$: Observable<any>;
  public satoriFiles$: SatoriFile[];
  private redundantRuns$: Observable<any>;
  private hasRedundantRuns: boolean = false;
  public mapLoaded: boolean = true;
  public downloadUri: string;
  public filedownloadstatus: FileDownloadStatus[];
  public brToken: string;
  https: any;
  httpClient: any;
  // perccentage = 0;
  count: any;

  constructor(private route: ActivatedRoute, private http: HttpClient,
    private service: RunsService,
    private satoriService: SatoriService,
    private titleService: Title,
    private router: Router,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
    if (userProfile == null) {
      this.route.paramMap.subscribe(r => {
        this.runId = +r.get("id");
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      this.router.navigate(['/run/details/', this.runId]);
    }
    this.BhaApiOld = "http://hdbstech/dbsservices/odata/bha";
    this.route.paramMap.subscribe(r => {
      this.runId = +r.get("id");
      this.service.getRunById(this.runId).subscribe(r => {
        this.run$ = r.data;
        this.titleService.setTitle(`Run Id: ${r.data.ID} - ${r.data.WellName}`);
        this.satoriService.getSatoriFilesByRunUUID(r.data.RunUUID).subscribe(response => {
          this.satoriFiles$ = response;
          this.satoriFiles$.forEach(element => {
            element.isDownloading = false;
            element.percentage = 0;
          });
        });
      });
    });
    //console.log(localStorage.getItem('accessToken'));
    // if(localStorage.getItem('accessToken') == null)
    //   setTimeout(() => {location.reload()},1000);
    //alert('Your in Onit function- line 48 - DetailsComponent');
    this.filedownloadstatus = [];
  }

  public offsetSearch(materialNumber): void {
    window.open(`http://hdbstech/Wizdom/OffSetSearch?WellSearch=matnum&materialnumber=${materialNumber}`);
  }

  mapLoadedEvent(status: boolean) {
    this.mapLoaded = status;
  }

  DownloadSatoriFiles() {
    let params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', '4658d431-df75-4b8c-859f-e07cea095836');
    params.append('client_secret', 'np9qKIyrli0_44673u8staP7dA__U~X-dV');
    params.append('scope', 'https://satori.halliburton.com/.default');
    //params.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    //params.append('Accept', '*/*');
    //params.append('Content-Length', '436');
    params.append('Access-Control-Allow-Origin', '*');
    //params.append('Access-Control-Allow-Headers', 'Content-Type'),
    params.append('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT'),
      params.append("Access-Control-Allow-Headers", "x-requested-with, authorization");
    params.append("Access-Control-Max-Age", "3600");;

    this.http.post('https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5/oauth2/v2.0/token',
      //this.http.post('https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5/oauth2/v2.0/token',
      //this.http.post('https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5/oauth2/token',
      //this.http.post('https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5/oauth2/v2.0/authorize',
      params.toString())
      .subscribe(
        data => this.saveToken(alert(data)),
        err => alert('Invalid Credentials'));
  }

  saveToken(token) {
    var expireDate = new Date().getTime() + (1000 * token.expires_in);
    sessionStorage.set("access_token", token.access_token, expireDate);
    console.log('Obtained Access token');
  }

  copyText(val: string) {
    this.brToken = "Authorization:Bearer" + ' ' + sessionStorage.getItem('accessToken');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "aria2c.exe" + ' ' + "--header=" + '"' + this.brToken + '"' + ' ' + this.BhaApi + "/Satori('" + val + "')";
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    Swal.fire({
      title: 'link copied successfully', width: '400px', position: 'top', icon: 'success', showConfirmButton: false, timer: 1000
    })
  }

  copyPdfText(val: number, userId: Variable)
  {
    this.brToken = "Authorization:Bearer" + ' ' + sessionStorage.getItem('accessToken');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';//this.http.get(this.BhaApi + "/RunSummaryReport('" + value + "')" + "?u=" + userId
    selBox.value = "aria2c.exe" + ' ' + "--header=" + '"' + this.brToken + '"' + ' ' + this.BhaApi + "/RunSummaryReport('" + val + "')" + "?u=" + userId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    Swal.fire({
      title: 'link copied successfully', width: '400px', position: 'top', icon: 'success', showConfirmButton: false, timer: 1000
    })
  }

  DownloadFilesOld(value: number, fileType: string, fileId: string) {
    this.satoriFiles$.find(x => x.FileName == fileType && x.Id == fileId).isDownloading = true;
    this.satoriFiles$.find(x => x.FileName == fileType && x.Id == fileId).percentage = 0;
    this.service.setDownloadFlag(true);
    let binaryData = [];
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')
    })

    this.http.get(this.BhaApiOld + "/Satori('" + value + "')", {
      responseType: 'blob',
      headers: reqHeaders,
      reportProgress: true,
      observe: 'body',
    }).subscribe(response => {
      setTimeout(() => {
        this.downLoadFile(response, fileType, fileId);
      }, 1000)

    });
    const req = new HttpRequest('GET', this.BhaApiOld + "/Satori('" + value + "')", {
      responseType: 'blob',
      headers: reqHeaders,
      reportProgress: true,
      observe: 'body',
    });

    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.satoriFiles$.find(x => x.FileName == fileType && x.Id == fileId).percentage = Math.round(100 * event.loaded / event.total);
      }
    });
  }

  DownloadFiles(value: number, fileType: string, fileId: string) {
    this.satoriFiles$.find(x => x.FileName == fileType && x.Id == fileId).isDownloading = true;
    this.satoriFiles$.find(x => x.FileName == fileType && x.Id == fileId).percentage = 0;
    this.service.setDownloadFlag(true);
    let binaryData = [];
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')
    })    
    this.http.get(this.BhaApi + "/Satori('" + value + "')", {
      responseType: 'blob',
      headers: reqHeaders,
      reportProgress: true,
      observe: 'body',
    }).subscribe(response => {
      setTimeout(() => {
        this.downLoadFile(response, fileType, fileId);
      }, 1000)
    },err => Swal.fire({
      title: 'Download failed', width: '400px', position: 'top', icon: 'error', showConfirmButton: false, timer: 1000
  }));
    const req = new HttpRequest('GET', this.BhaApi + "/Satori('" + value + "')", {
      responseType: 'blob',
      headers: reqHeaders,
      reportProgress: true,
      observe: 'body',
    });
    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.satoriFiles$.find(x => x.FileName == fileType && x.Id == fileId).percentage = Math.round(100 * event.loaded / event.total);
      }
    });
  }

  downLoadFile(data: any, type: string, fileId: string): void {
    let blob = new Blob([data], {
      type: type.split(".").pop()
    });
    let url = window.URL.createObjectURL(blob);
    FileSaver.saveAs(blob, type.trim());
    // var link = window.document.createElement('a');
    // link.href = url;
    // link.download = type;
    // if (this.count) {
    //   clearInterval(this.count);
    // }
    // link.click();
    // window.URL.revokeObjectURL(url);
    this.satoriFiles$.find(x => x.FileName == type && x.Id == fileId).isDownloading = false;
  }
  downLoadFPDFile(data: any, type: any): void {
    let blob = new Blob([data], {
      type: type.split(".").pop()
    });
      FileSaver.saveAs(blob, type.trim());
    // let url = window.URL.createObjectURL(blob);
    // var link = document.createElement('a');
    // link.href = url;
    // link.download = type;//+ ".csv";
    // link.click();
    // window.URL.revokeObjectURL(url);
    // var file_saver_1 = require('file-saver');
    // file_saver_1.saveAs(blob, type.trim());
    // window.saveAs(blob, type.trim());
    // const saveFile = async (blob) => {
    //   debugger;
    //   const handle = await window.saveAs(blob, type.trim());
    //   // const writable = await handle.createWritable();
    //   // await writable.write(blob);
    //   // await writable.close();
    //   return handle;
    //   };
    this.filedownloadstatus.forEach((value, index) => {
      if (value.fileName == type) this.filedownloadstatus.splice(index, 1);
    });
  }
  DownloadPdf(value: number, userId: Variable) {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')
    })
    this.http.get(this.BhaApi + "/RunSummaryReport('" + value + "')" + "?u=" + userId, {
      observe: 'response',
      responseType: 'blob',
      headers: reqHeaders
    }).subscribe(response => {
      var fileName = "";
      var contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      this.filedownloadstatus.find(x => x.runId == value && x.fileName.includes("Run Summary Report")).fileName = fileName;
      this.downLoadFPDFile(response.body, fileName);
    });
    const req = new HttpRequest('GET', this.BhaApi + "/StatsFile('" + value + "')", {
      responseType: 'blob',
      headers: reqHeaders,
      reportProgress: true,
      observe: 'body',
    });
    var fileName = "";
    var contentDisposition = req.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    this.filedownloadstatus.push({
      fileName: "Downloading Run Summary Report",
      percentage: 0,
      isDownloading: true,
      runId: value,
    });
    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.filedownloadstatus.find(x => x.runId == value && x.fileName.includes("Run Summary Report")).percentage = Math.round(100 * event.loaded / event.total);
      }
    });
  }

}
