import {Injectable} from '@angular/core';
import { Observable } from 'rxjs'
import {HttpClient, HttpHeaders} from '@angular/common/http';

export interface RunsOverTime {
    RunDate: string;
    Runs:number;
    RunningTotal:number;
  }

@Injectable()
export class BackendService {
  constructor(private http: HttpClient) {}

  getRunsOverTime(): Observable<RunsOverTime[]> {
    return this.http.get<RunsOverTime[]>('http://localhost:8000/api/bha/runsovertime')
  }

}