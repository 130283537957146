import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GraphsComponent }      from './graphs.component'

const graphsRoutes: Routes = [
  { path: 'graphs',  component: GraphsComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(graphsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class GraphsRoutingModule { }