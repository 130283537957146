import { Component, OnInit, Injectable, Input } from '@angular/core';
import { UploadEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';

declare var FILE_VAULT_API:string;
declare var CURRENT_USER_ID:string;

@Component({
  selector: 'file-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  public uploadSaveUrl:string = `${FILE_VAULT_API}/Files/CheckIn`; // should represent an actual API endpoint
  public uploadRemoveUrl:string = `${FILE_VAULT_API}/Files/Delete`; // should represent an actual API endpoint

  @Input()
  public runId:number;
  @Input()
  public applicationFileType:number;
  @Input()
  public fileDescription:string='';
  @Input()
  public note:string='';
  @Input()
  public selectButtonText:string='Upload file';  

  files: Array<FileInfo> = [
    { name: 'First.txt', size: 500 },
    { name: 'Second.jpg', size: 100 }
  ];

  constructor() { }

  ngOnInit() {
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      fileDescription: this.fileDescription,
      revision: 'A',
      note: this.note,
      userId: CURRENT_USER_ID,
      targetId: this.runId,
      applicationType: this.applicationFileType,
    };
  }  

  removeEventHandler(e: RemoveEvent) {
    e.data = {
      description: 'File removed'
    };
  }

  public remove(upload, uid: string) {
    upload.removeFilesByUid(uid);
  }  
}