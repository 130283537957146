import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ListComponent }              from './list/list.component';
import { RedundantRunsListComponent } from './list/redundant-runs-list.component';
import { DetailsComponent }           from './details/details.component';
import { DeleteRunComponent }         from './delete-run.component';
import { BhaCommonModule }            from '../common/common.module';
import { HistogramComponent }         from './histogram.component';

import { RunRoutingModule } from './run-routing.module';
import { RunsBindingDirective } from './remote-binding.directive';
import { RunsService, HistogramsService, MaterialNumberService } from '../common/bha.service';
import { RunService } from '../common/run.service';

import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { TicksToHoursPipe } from '../custom-pipes'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ByMaterialNumberComponent } from './by-material-number/by-material-number.component';

import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SatoriService } from '../common/satori.service';

import {NgxFilesizeModule} from 'ngx-filesize';

@NgModule({
  imports: [
    CommonModule,
    RunRoutingModule,
    HttpClientModule,
    GridModule,
    ChartsModule,
    GaugesModule,
    ExcelModule,
    BhaCommonModule,
    NgbDropdownModule,
    NgxFilesizeModule,
    LayoutModule
  ],
  declarations: [ListComponent, RedundantRunsListComponent, DetailsComponent, HistogramComponent, DeleteRunComponent, RunsBindingDirective, TicksToHoursPipe, ByMaterialNumberComponent],
  providers: [ RunsService, HistogramsService, MaterialNumberService, RunService, NgbModal, SatoriService ],
  exports:[RunsBindingDirective]
})
export class RunModule { }
