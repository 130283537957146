<div *ngIf="run$" class="container-fluid">
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'Details'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row justify-content-between">
                    <div class="col">
                        <h2>Run #{{run$.ID}} Details</h2>
                    </div>
                    <div class="col text-right">
                        <!-- <delete-run [runId]="run$.ID"></delete-run> -->
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-8">
                        <dl class="row">
                            <dt class="col-sm-3">Run</dt>
                            <dd class="col-sm-9">
                                <dl class="row">
                                    <dt class="col-sm-4">Uploaded By</dt>
                                    <dd class="col-sm-8">{{run$.CreatedBy}}</dd>
                                    <dt class="col-sm-4">Sample Start Time</dt>
                                    <dd class="col-sm-8">{{ run$.SampleStartTime | date:'short' }} UTC</dd>
                                    <dt class="col-sm-4">Sensor Board Serial Number</dt>
                                    <dd class="col-sm-8"><a
                                            routerLink="/board/details/{{run$.SensorBoardSerialNumber}}">{{
                                            run$.SensorBoardSerialNumber }}</a></dd>
                                    <dt class="col-sm-4">Number</dt>
                                    <dd class="col-sm-8">{{ run$.RunNumber }}</dd>
                                    <dt class="col-sm-4">Length (in hours)</dt>
                                    <dd class="col-sm-8">{{run$.TotalSampleTicks | ticksToHours | number : '1.0-0' }}
                                        hours</dd>
                                </dl>
                            </dd>
                            <dt class="col-sm-3">Bit/Sub</dt>
                            <dd class="col-sm-9">
                                <dl class="row">
                                    <dt class="col-sm-4">Serial Number</dt>
                                    <dd class="col-sm-8"><span style="cursor:pointer;" title="Offset Search"
                                            (click)="offsetSearch(run$.BitSubSerialNumber)">{{ run$.BitSubSerialNumber
                                            }}</span></dd>
                                    <ng-template [ngIf]="run$.BitMaterialNumber">
                                        <dt class="col-sm-4">Material Number</dt>
                                        <dd class="col-sm-8"><a title="Marketing Sheet"
                                                href="http://hdbstech/MarketingSheets/{{run$.BitMaterialNumber}}"
                                                target="_blank">{{run$.BitMaterialNumber}}</a></dd>
                                    </ng-template>
                                    <ng-template [ngIf]="run$.BitType">
                                        <dt class="col-sm-4">Type</dt>
                                        <dd class="col-sm-8">{{run$.BitType}}</dd>
                                    </ng-template>
                                    <ng-template [ngIf]="run$.BitSize">
                                        <dt class="col-sm-4">Size</dt>
                                        <dd class="col-sm-8">{{run$.BitSize}}"</dd>
                                    </ng-template>
                                </dl>
                            </dd>
                            <dt class="col-sm-3">Well</dt>
                            <dd class="col-sm-9">
                                <dl class="row">
                                    <dt class="col-sm-4">Name</dt>
                                    <dd class="col-sm-8">{{run$.WellName}}</dd>
                                    <dt class="col-sm-4">Location</dt>
                                    <dd class="col-sm-8"><a title="Offset Search"
                                            href="http://hdbstech/Wizdom/OffSetSearch?WellSearch=y&Latitude={{run$.Latitude}}&Longitude={{run$.Longitude}}"
                                            target="_blank">{{ run$.Latitude }}, {{ run$.Longitude }}</a></dd>
                                </dl>
                            </dd>
                            <dt class="col-sm-3">Comment</dt>
                            <dd class="col-sm-9">{{run$.Comments}}</dd>
                        </dl>
                    </div>
                    <div class="col-4">
                        <ng-template [ngIf]="mapLoaded" [ngIfElse]="noMap">
                            <run-map [center]="[+run$.Longitude, +run$.Latitude]" [basemap]="'streets'" [zoom]="'6'"
                                [plotCenter]="true" [wellName]="run$.WellName" [runNumber]="run$.RunNumber"
                                (mapLoaded)="mapLoadedEvent($event)"></run-map>
                        </ng-template>
                        <ng-template #noMap>
                            <p class="lead">Unable to load the map due to missing or inaccurate location data for this
                                run.</p>
                        </ng-template>
                    </div>
                </div>
                <!-- <hr/>
                <div class="row">
                    <div class="col"></div>
                    <div class="col-4">
                        <p class="lead">Standard Report</p>
                        <file-upload [runId]="run$.ID" [applicationFileType]="28" [fileDescription]="'Standard Report'" [selectButtonText]="'Upload standard report'"></file-upload>
                    </div>
                    <div class="col"></div>
                    <div class="col-4">
                        <p class="lead">Other files (note: raw data should be uploaded to XSpace)</p>
                        <file-upload [runId]="run$.ID" [applicationFileType]="29" [fileDescription]="'Other'" [selectButtonText]="'Upload other file'"></file-upload>
                    </div>    
                    <div class="col"></div>    
                </div> -->
                <hr />
                <div class="row mb-4">
                    <div class="col">
                        <div class="col">
                            <div class="card">
                                <histogram [runId]="run$.ID" [type]="'Temperature_deg_C'"></histogram>
                            </div>
                        </div>
                    </div>
                    <div class="col text-center">
                        <ng-template [ngIf]="run$.BitMaterialNumber">
                            <img src="http://hdbstech/BitImage.axd?type=fc&id={{run$.BitMaterialNumber}}&height=400">
                        </ng-template>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col">
                        <div class="col">
                            <div class="card">
                                <histogram [runId]="run$.ID" [type]="'AxialVibration_g'"></histogram>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="col">
                            <div class="card">
                                <histogram [runId]="run$.ID" [type]="'LateralVibration_g'"></histogram>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col">
                        <div class="col">
                            <div class="card">
                                <histogram [runId]="run$.ID" [type]="'StickSlipIndicator'"></histogram>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="col">
                            <div class="card">
                                <histogram [runId]="run$.ID" [type]="'TorsionalVibration_deg_per_sec_2'"></histogram>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col">
                            <div class="card">
                                <histogram [runId]="run$.ID" [type]="'WhirlRadius_mm'"></histogram>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <!-- <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Redundant Runs</h5>
                                    <ng-template [ngIf]="hasRedundantRuns" [ngIfElse]="noRedundantRuns">
                                        <redundant-runs-list [parentRunId]="run$.ID"></redundant-runs-list>
                                    </ng-template>
                                    <ng-template #noRedundantRuns>
                                        <p class="lead">No redundant runs were found for this run. Redundant runs are identified by board serial number, start time and run length.</p>
                                    </ng-template>
                                </div>
                            </div>             -->
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Files'">
            <ng-template kendoTabContent>
                <div *ngIf="satoriFiles$" class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <ng-container *ngIf="run$.HasStatsFile>1">
                                <h4>Run Summary Report</h4>
                                <!-- <a style="cursor:pointer;" href="{{BhaApi}}/RunSummaryReport('{{run$.RunUUID}}')?u={{currentUserId}}"><i class="material-icons">picture_as_pdf</i>Generate and download</a> -->
                                <div>
                                    <a style="cursor:pointer;" (click)="DownloadPdf(run$.RunUUID,currentUserId)"><i
                                            class="material-icons">picture_as_pdf</i>
                                            <p1 style="margin: 5px;vertical-align: top;">Generate and download</p1>
                                    </a>
                                    <button id="btnCopy" title="Copy aria2 command with needed arguments" (click)="copyPdfText(run$.RunUUID,currentUserId);"
                                        style="border: transparent;background: transparent;cursor:pointer;">
                                        <span class="material-icons">content_copy</span>
                                    </button>
                                </div>
                                <br /><i><small>Note: Generating the report could take a few minutes.</small></i>
                                <ng-container *ngFor="let file of filedownloadstatus; let i = index">
                                    <div class="progress" *ngIf="file.isDownloading">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                            [ngStyle]="{'width':file.percentage+ '%'}">
                                            {{file.percentage}}% {{file.fileName}}...</div>
                                    </div>
                                </ng-container>
                                <br />
                            </ng-container>
                            <h4>Data Files</h4>
                            <table class="table table-striped">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">Filename</th>
                                        <!-- <th scope="col">Old Method(For Testing)</th>
                                        <th scope="col">Old API(For Testing)</th>
                                        <th scope="col">Old API and Old Method(For Testing)</th> -->
                                        <th scope="col">Upload Date</th>
                                        <th scope="col">File Size</th>
                                        <th scope="col">Copy Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let file of satoriFiles$; let i = index">
                                        <tr
                                            [style.border-top]="i > 0 && file.DatabaseLinkId != satoriFiles$[i-1].DatabaseLinkId ? 'solid 5px black' : ''">
                                            <th scope="row">
                                                <ng-container *ngIf="file.FileSize>0; else zeroFileSize">
                                                    <!-- <a title="Download File" style="cursor:pointer;" href="{{BhaApi}}/Satori('{{file.SatoriFileGuid}}')"><i class="material-icons">save</i> {{file.FileName}}</a> -->
                                                    <a title="Download File" style="cursor:pointer;"
                                                        (click)="DownloadFiles(file.SatoriFileGuid,file.FileName,file.Id)"><i
                                                            class="material-icons">save</i> {{file.FileName}}</a>
                                                </ng-container>
                                                <ng-template #zeroFileSize>
                                                    <i class="material-icons disabled">save</i> {{file.FileName}}
                                                </ng-template>
                                            </th>
                                            <!-- <th scope="row">
                                                <ng-container *ngIf="file.FileSize>0; else zeroFileSize">
                                                    <a title="Download File" style="cursor:pointer;"
                                                        href="{{BhaApi}}/Satori('{{file.SatoriFileGuid}}')"><i
                                                            class="material-icons">save</i> {{file.FileName}}</a>                                                  
                                                </ng-container>
                                                <ng-template #zeroFileSize>
                                                    <i class="material-icons disabled">save</i> {{file.FileName}}
                                                </ng-template>
                                            </th>
                                            <th scope="row">
                                                <ng-container *ngIf="file.FileSize>0; else zeroFileSize">
                                                   <a title="Download File" style="cursor:pointer;"
                                                        (click)="DownloadFilesOld(file.SatoriFileGuid,file.FileName,file.Id)"><i
                                                            class="material-icons">save</i> {{file.FileName}}</a>
                                                </ng-container>
                                                <ng-template #zeroFileSize>
                                                    <i class="material-icons disabled">save</i> {{file.FileName}}
                                                </ng-template>
                                            </th>
                                            <th scope="row">
                                                <ng-container *ngIf="file.FileSize>0; else zeroFileSize">
                                                    <a title="Download File" style="cursor:pointer;"
                                                        href="{{BhaApiOld}}/Satori('{{file.SatoriFileGuid}}')"><i
                                                            class="material-icons">save</i> {{file.FileName}}</a>
                                                </ng-container>
                                                <ng-template #zeroFileSize>
                                                    <i class="material-icons disabled">save</i> {{file.FileName}}
                                                </ng-template>
                                            </th> -->
                                            <!-- <th scope="row">
                                                <ng-container *ngIf="file.FileSize>0; else zeroFileSize">
                                                    <a title="Download File" style="cursor:pointer;"
                                                        (click)="DownloadSatoriFiles(file.SatoriFileGuid,file.FileName,file.Id)"><i
                                                            class="material-icons">save</i> {{file.FileName}}</a>
                                                </ng-container>
                                                <ng-template #zeroFileSize>
                                                    <i class="material-icons disabled">save</i> {{file.FileName}}
                                                </ng-template>
                                            </th> -->
                                            <td>{{file.CreatedDate}}</td>
                                            <td>{{file.FileSize | filesize}}</td>
                                            <td><button id="btnCopy" title="Copy aria2 command with needed arguments" (click)="copyText(file.SatoriFileGuid);" style="border: transparent;background: transparent;cursor:pointer;">
                                                <span class="material-icons">content_copy</span>
                                            </button>
                                        
                                            <!-- <button aria-describedby="description" id="btnCopy" (click)="copyText(file.SatoriFileGuid);" 
                                                style="border: transparent;background: transparent;cursor:pointer;">
                                                <span class="material-icons">content_copy</span>
                                                </button>
                                                <p id="description" role="tooltip">Edit your settings</p> -->
                                        </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style="position:fixed;padding:7px;top:0;right:0;width:50%;z-index:999">
                    <ng-container *ngFor="let file of satoriFiles$; let i = index">
                        <div class="progress" *ngIf="file.isDownloading">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                [ngStyle]="{'width':file.percentage+ '%'}">{{file.percentage}}% {{file.FileName}}...
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- <ng-container  *ngFor="let file of satoriFiles$; let i = index"> 
                    <div class="progress" *ngIf="file.isDownloading">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':file.percentage+ '%'}">{{file.percentage}}% {{file.FileName}}...</div>
                      </div> 
                    </ng-container>     -->
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>