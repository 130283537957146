import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var SpotFireServerUrl:string;

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.css']
})
export class GraphsComponent implements OnInit {
  spotFireServerUrl:string = SpotFireServerUrl;
  cerebrographsUrl;
  constructor(private domSanitizer : DomSanitizer) { }

  ngOnInit() {

    this.cerebrographsUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.spotFireServerUrl  + 'file=/HDBSTech/HDBS/Cerebro/CerebroAnalysis&options=3-0,10-0,13-0,14-0,15-0;');
  }

}
