<div class="container-fluid">
    <div class="row">
        <div class="col">
            <kendo-grid 
                PersistGridSettings="BHA"
                boardsBinding
                id="BoardsList"
                [sortable]="true"
                [sort]="[{ 'dir': 'desc', 'field': 'LastRunDate' }]"
                [filterable]="true"
                [resizable]="true"
                [pageable]="{ buttonCount: 5, info: true, type: 'numeric', pageSizes: true, previousNext: true }"
                [pageSize]="20"
                [columnMenu]="true"
                [reorderable]="true">
                <ng-template kendoGridNoRecordsTemplate>Please wait while the data loads...</ng-template>
                <ng-template kendoGridToolbarTemplate>
                        <div class="row justify-content-between" style="padding: 8px;background-color: #fafafa;margin: 0px;margin-right: -1px;border-style: ridge;border-block-color: inherit;border-width: thin ">
                            <div class="col-4" style="padding-left: 0px;"><h5 class="lead">All Boards</h5></div>
                            <div class="col-4 text-right" style="margin-right: 4%;">
                                <div ngbDropdown class="d-inline-block">
                                    <button class="btn btn-outline-secondary btn-sm" id="exportToExcelDropdownMenuLink" ngbDropdownToggle>Export to Excel</button>
                                    <div ngbDropdownMenu aria-labelledby="exportToExcelDropdownMenuLink">
                                        <button (click)="onExportClick('visible')" class="dropdown-item">Current page</button>
                                        <button (click)="onExportClick('all')" class="dropdown-item">All pages</button>
                                    </div>
                                </div>                            
                                <kendo-grid-excel ></kendo-grid-excel>
                            </div>
                        </div>
                    </ng-template>                
                <kendo-grid-column field="SerialNumber" title="Serial Number" width="120">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <a routerLink="/board/details/{{dataItem.SerialNumber}}">{{ dataItem.SerialNumber }}</a> -->
                        <a style="cursor:pointer;" (click)="sensorBrdDetails(dataItem.SerialNumber)">{{ dataItem.SerialNumber }}</a>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="Runs" title="# Runs" width="75">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-sparkline [data]="[dataItem.Runs, maximumRuns*.75]" type="bullet" [valueAxis]="bulletValueAxis">
                        </kendo-sparkline>
                        {{dataItem.Runs}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="FirstRunDate" title="First Run Date" width="100" filter="date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.FirstRunDate | date:'short' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="LastRunDate" title="Last Run Date" width="100" filter="date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.LastRunDate | date:'short' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="TotalRunHours" title="Total Run Hours" width="80" filter="numeric">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-sparkline [data]="[dataItem.TotalRunHours, maximumRunHours*.75]" type="bullet" [valueAxis]="runHoursBulletValueAxis">
                        </kendo-sparkline>
                        {{dataItem.TotalRunHours}}
                    </ng-template>                    
                </kendo-grid-column>
                <!-- <kendo-grid-column field="TotalFileWriteErrors" title="Total File Write Errors" width="80" filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="TotalSensorErrors" title="Total Sensor Errors" width="80" filter="numeric"></kendo-grid-column>
                <kendo-grid-column field="TotalParseErrors" title="Total Parse Errors" width="80" filter="numeric"></kendo-grid-column> -->
                <kendo-grid-column field="ID" title="Actions" width="80" [filterable]="false" [sortable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a routerLink="/board/details/{{dataItem.SensorBoardSerialNumber}}"><i class="material-icons">search</i></a>
                    </ng-template>
                </kendo-grid-column>   
                <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-info></kendo-pager-info>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                    <kendo-pager-page-sizes [pageSizes]="[20, 50, 100]"></kendo-pager-page-sizes>
                </ng-template>
            </kendo-grid>      
        </div>
    </div>
</div>

