import {Injectable} from '@angular/core';
import { Observable } from 'rxjs'
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare var BHA_API:string;

export interface SatoriFile {
  runUUID: string;
  databaseLinkId: string;
  fileType: string;
  fileName: string;
  fileSize: Int16Array;
  xaviFileGuid: string;
  fileHash: string;
  createdDate: Date;
  isDownloading :boolean;
  percentage :number;
  FileName: string;
  Id: string;
}

@Injectable()
export class SatoriService {
  BhaApi:string = BHA_API;

  constructor(private httpClient: HttpClient) {}

  getSatoriFilesByRunUUID(runUuid: string): Observable<SatoriFile[]> {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + sessionStorage.getItem('accessToken')});
    return this.httpClient.get<SatoriFile[]>(BHA_API + "/SatoriFile('" + runUuid + "')", { headers: reqHeaders });
  }
}