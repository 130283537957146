import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-by-material-number',
  templateUrl: './by-material-number.component.html',
  styleUrls: ['./by-material-number.component.css']
})
export class ByMaterialNumberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
