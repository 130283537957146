import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string,
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile!: ProfileType;
  userDetails: any;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {    
    this.userDetails = JSON.parse(sessionStorage.getItem('userProfile'));
    var test = localStorage.getItem(localStorage.key(0));
  }

  // getProfile() {
  //   this.http.get(GRAPH_ENDPOINT)
  //     .subscribe(profile => {
  //       this.profile = profile;
  //       console.log(this.profile);
  //       this.userDetails = this.profile;
  //     });
  // }
}
